<template>
  <div>
    <a-modal :visible="visible"  @cancel="cancel" width="35%" @ok="submit" :confirmLoading="btnLoading" :bodyStyle="{padding:'10px'}">
      <template #title>
        渠道配置 <span class="m-l2"><a-checkbox @change="changeAll" v-model:checked="allChecked">选择全部</a-checkbox></span>
    </template>
      <div v-if="loading1" class="flex ju-center">
        <a-spin />
      </div>
      <div v-else class="checkBox">
        <div v-for="item in ProvinceList" :key="item.id">
          <div class="label"> <span class="m-r1">{{ item.label }}</span> <a-checkbox @change="change($event,item)" v-model:checked="item.allChecked">全选</a-checkbox></div>
          <div class="flex wrap " style="margin-left: 10px">
            <div v-for="item2 in item.children" :key="item2.id" style="width:25%;margin-bottom:5px">
              <a-checkbox v-model:checked="item2.checked" @change="itemChange(item)" :value="item2.id">{{ item2.label }}</a-checkbox>
            </div>
          </div>
        </div>
      </div>
  
    </a-modal>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'
import { cloneDeep } from 'lodash-es';
const store = useStore()

const emit = defineEmits(['update:visible', 'success'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  selProvince: {
    type: Array,
    default: []
  },
})
const ProvinceList = ref([])
const loading1 = ref(false)
const btnLoading = ref(false)
const allChecked = ref(false)
const itemChange = (item) => {
  item.allChecked =   item.children.every( item2 => item2.checked)
  allChecked.value = ProvinceList.value.every(item => item.allChecked)
}
const changeAll = (e) => {
  if (e.target.checked) {
    ProvinceList.value.forEach(item => {
      item.children.forEach(item2 => {
        item2.checked = true
      })
      item.allChecked = true
    })
  } else {
    ProvinceList.value.forEach(item => {
      item.children.forEach(item2 => {
        item2.checked = false
      })
      item.allChecked = false
    })
  }
}
const change = (e, item) => {
  if (e.target.checked) {
    item.children.forEach(item2 => {
      item2.checked = true
    })
  } else {
    item.children.forEach(item2 => {
      item2.checked = false
    })
  }
  allChecked.value = ProvinceList.value.every(items => items.allChecked)
}
const cancel = () => {
  ProvinceList.value = []
  emit('update:visible', false)
}
const submit = () => {
  let list = []
  ProvinceList.value.forEach(item => {
    item.children.forEach(item1 => {
      if (item1.checked) {
        list.push(item1)
      }
    })
  })
  emit('success', list)
}
watch(() => props.visible, (val, oldVal) => {
  if (val) {
    setData()
  }
})
const setData = () => {
  let data = cloneDeep(store.state.enumAll.ChannelTypeEnum)
  console.log(data, props.selProvince);
  if (props.selProvince?.length > 0) {
    props.selProvince.forEach(item => {
      data.forEach(item2 => {
        item2.children.forEach(item3 => {
          if (item.value == item3.value) {
            item3.checked = true
          }
        })
        if(item2.children.every(item4 => item4.checked)){
          item2.allChecked = true
        }else{
          item2.allChecked = false
        }
      })
      
    })
    if(data.every(item4 => item4.allChecked)){
        allChecked.value = true
      }else{
        allChecked.value = false

      }
  }
  ProvinceList.value = data
}

</script>

<style lang="less" scoped>
.checkBox {
  max-height: 600px;
  overflow: auto;
}
.label {
  margin-top: 6px;
  margin-bottom: 3px;
}
</style>