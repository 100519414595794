<template>
  <div style="flex:1;padding: 10px 15px;width:100%;padding-top:0;">
    <div>
      <a-table :columns="transColumns" :data-source="transList" :pagination="false" bordered :locale="{}" :row-key="record => record.totvId" :scroll="{ x: 2000 }" size="small">
        <template #carNo="{ record }">
          <a @click="lookCarNo(record)">{{ record.carNo }}</a>
        </template>
        <template #carrierName="{ record }">
          <div><a @click="carrierEdit(record)">{{ record.carrierName }}</a></div>
        </template>
        <template #transportFee="{ record }">
          <div>
            <a @click="editMoney(record)">{{ record.transportFee }}</a>
          </div>
        </template> 
        <template #settlementStatus="{ record }">
          对账：<a @click="settlementData(record.ftsId, record.billStatus)">{{ record.billStatus ?
            record.billStatus.label : '' }}</a>
          <a-divider type="vertical" />
          <span>{{ record.settlementStatus ? record.settlementStatus.label : '' }}</span>
        </template>
        <template #orderSettlement="{ record }">
          <div>
            {{ record.settlementMode?.label }}
          </div>
        </template>
        <template #repeal="{ record }">
          <div v-if="record.show && record.transportType?.value !== 0 && record.truckStatus?.value === 4">
            <a-popconfirm ok-text="是" cancel-text="否" @confirm="repealClick(record)">
              <template #title>
                <div>
                  <p>确认撤销？</p>
                  <a-textarea v-model:value="repealRemark" placeholder="备注信息" :rows="4" />
                </div>
              </template>
              <a>撤销到达</a>
            </a-popconfirm>
          </div>
          <a style="margin-left:10px" v-show="record.transportType?.value === 0" @click="handleCope(record, 1)">验车码</a>
        </template>
      </a-table>
    </div>
    <div v-if="dispatchDetail.tasks?.length > 0" class="m-t2 m-b2">
      <div class="flex ju-between al-center m-t1 m-b1">派单任务
      </div>
      <a-table size="small" :columns="columnsSend" :scroll="{ x: 1500 }" bordered :row-key="record => record.id" :data-source="dispatchDetail.tasks" :pagination="false">
        <template #vinNo="{ record }">
          <div> {{ record.vinNo }} </div>
          {{ record.brand }} {{record.model}}
        </template>
        <template #image="{ record }">
          <span v-if="record.pic?.value === 0">{{ record.pic?.label}}</span>
          <a v-else @click="lookVehicleImg(record)">{{record.picNum}}张</a>
        </template>
        <template #deliveryCard="{ record }">
          <span v-if="record.deliveryCard?.value === 0">{{ record.deliveryCard?.label}}</span>
          <a v-else @click="lookDeliveryCard(record)">查看</a>
        </template>
        <template #address="{ record }">
          <span v-if="record.address?.value === 0">{{ record.address?.label }}</span>
          <a v-else @click="lookVehicleGps(record)">查看</a>
        </template>
        <template #add="{ record }">
          <a-button size="small" @click="taskOperation(record)" type="primary">操作</a-button>
        </template>
      </a-table>
      <!-- 在途位置 -->
      <VehicleGps ref="vehicleGps" />
      <!-- 交车条 -->
      <PictureViewer v-model:visible="visibleLookDeliver" :lookImgID="lookImgID"></PictureViewer>
      <!-- 详情模块 -->
      <DetailsModal v-model:visible="detailsShow" :lookID="lookID" @statusAdd="revokeSend" v-if="detailsShow"></DetailsModal>
    </div>
    <div style="height:40px" v-if="dispatchDetail.tasks?.length > 0"></div>
    <div v-show="operateDetail.executeStatus?.value !== 9&&!dispatchDetail.taskId">
      <div class="operate-div" style="width: 100%;" v-show="operateDetail.executeType?.value === 0">
        <div class="operate-title" style="display:flex;justify-content:space-between">
          <span>安排验车</span>
          <a @click="reportClick">异常上报</a>
        </div>
        <div class="operate-content">
          <div style="display:flex;justify-content:space-between;color:#344563;font-size: 12px;">
            <div>验车地址：{{ operateDetail.startAddress }}</div>
            <div>预约验车时间：{{ operateDetail.subscribeTime || '暂无' }}</div>
          </div>
          <a-divider />
          <div style="display: flex;justify-content: space-between;margin-bottom:10px">
            <span v-show="!operateDetail.carrierName">
              <span style="color:#0066CC;margin-right:10px">系统推荐方案</span>
              <span style="color:#6B778C" v-show="operateDetail.sysRecommend">
                <span>{{ operateDetail.sysRecommend?.startAddress?.details }}</span>
                <span v-show="operateDetail.sysRecommend?.startAddress">——</span>
                <span> {{ operateDetail.sysRecommend?.endAddress?.details }}</span>
              </span>
            </span>
            <span style="color:#0066CC" v-show="operateDetail.carrierName">已选择方案</span>
            <div style="width:206px;color:#6B778C;justify-content: flex-end;display:flex;align-items:center">
              <div style="display:flex;align-items:center" v-show="!operateDetail.truckStatus"> 请发送委托和承运商确认
                <ArrowRightOutlined style="font-size: 12px" />
              </div>
              <a style="color:#0066CC" @click="getEntrust">委托</a>
            </div>
          </div>
          <div style="display:flex;justify-content: space-between;font-size: 12px;color:#6B778C">
            <div v-show="operateDetail.sysRecommend || operateDetail.truckStatus">
              <span style="margin-right:15px" v-show="!operateDetail.truckStatus">{{
                operateDetail.sysRecommend?.carrierName }}</span>
              <span style="margin-right:15px" v-show="operateDetail.truckStatus">{{ operateDetail.carrierName }}</span>
              <span>费用 ￥
                <span v-show="operateDetail.carrierName === null && operateDetail.sysRecommend">{{
                  operateDetail.sysRecommend?.cost }}</span>
                <span v-show="operateDetail.carrierName !== null">{{ operateDetail.transportFee }}</span>
              </span>
              <span class="m-l1">{{operateDetail.truckType?.label}}{{operateDetail.carNo}}</span>
            </div>
            <div v-show="!operateDetail.sysRecommend && !operateDetail.truckStatus">暂无</div>
            <div style="display: flex;align-items: center;" v-show="operateDetail.orderVehicleTransType !== -1">
              <span>验车照片</span>
              <span>&emsp;</span>
              <a-button type="primary" size="small" @click="updateImg">{{ operateDetail.picNum }}张</a-button>
              <span>&emsp;</span>
              <div class="operation">
                <img class="img" src="@/assets/images/carrier/QRC.png" @click="handleCope()">
              </div>
              <span>&emsp;</span>
              <div><img class="img" src="@/assets/images/carrier/employee.png" @click="evidenceShow = true"></div>
            </div>
            <div style="display: flex;align-items:center" v-show="operateDetail.truckStatus === null">
              <span v-show="operateDetail.sysRecommend">如和承运商达成一致，点击<a-button type="dashed" size="small" :loading="btnLoading" @click="confirm(operateDetail.executeType?.value)">确认</a-button>如未能达成点击
              </span>
              <span v-show="!operateDetail.sysRecommend">该线路没有系统推荐承运商，请点击</span>
              <a @click="arrangeShow = true, carrierData.chooseType = 2">人工选择</a>
            </div>
          </div>
          <div style="margin-top:10px" v-show="operateDetail.truckStatus?.value >= 0">
            <a-button type="dashed" :loading="btnLoading" v-show="operateDetail.orderVehicleTransType === 2 && type !== 1" @click="nullVisible = true">放空</a-button>
            <a-button type="dashed" :loading="btnLoading" v-show="operateDetail.orderVehicleTransType === -1 || operateDetail.orderVehicleTransType === 0" @click="arrangeShow = true">安排验车</a-button>
            <a-button type="dashed" :loading="btnLoading" v-show="operateDetail.orderVehicleTransType === 2" @click="arrangeShow = true">修改安排</a-button>
            <a-popconfirm :title="confirmTitle" ok-text="确定" cancel-text="取消" @confirm="inspectorComplete">
              <a-button type="dashed" :loading="btnLoading" @click="prompt" v-show="operateDetail.orderVehicleTransType === 2">验车完成</a-button>
            </a-popconfirm>
          </div>
        </div>
      </div>

      <div class="operate-div" style="width: 100%;" v-show="operateDetail.executeType?.value === 1">
        <div class="operate-title" style="display:flex;justify-content:space-between">
          <span>取车安排</span>
          <a @click="addReportShow = true, abnormalTask = operateDetail.taskId">异常上报</a>
        </div>
        <div class="operate-content">
          <div style="display:flex;justify-content:space-between;color:#344563;font-size: 12px;">
            <div>取车线路：{{ operateDetail.startAddress + '——' + operateDetail.endAddress }}</div>
            <div>取车方式：{{ operateDetail.pickType?.label }}</div>
          </div>
          <a-divider />
          <div style="display: flex;justify-content: space-between;margin-bottom:10px">
            <span v-show="!operateDetail.carrierName">
              <span style="color:#0066CC;margin-right:10px">系统推荐方案</span>
              <span style="color:#6B778C" v-show="operateDetail.sysRecommend">
                {{ operateDetail.sysRecommend?.startAddress?.details }}
                <span v-show="operateDetail.sysRecommend?.startAddress">——</span>
                {{ operateDetail.sysRecommend?.endAddress?.details }}
              </span>
            </span>
            <span style="color:#0066CC" v-show="operateDetail.carrierName">已选择方案</span>
            <div style="width:206px;color:#6B778C;text-align: right;">
              <span v-show="!operateDetail.truckStatus"> 请发送委托和承运商确认
                <ArrowRightOutlined style="font-size: 12px;width:20px" />
              </span>
              <a style="color:#0066CC" @click="getEntrust">委托</a>
            </div>
          </div>
          <div style="display:flex;justify-content: space-between;font-size: 12px;color:#6B778C">
            <div v-show="operateDetail.sysRecommend || operateDetail.truckStatus">
              <span style="margin-right:15px" v-show="!operateDetail.truckStatus">{{
                operateDetail.sysRecommend?.carrierName
              }}</span>
              <span style="margin-right:15px" v-show="operateDetail.truckStatus">{{ operateDetail.carrierName }}</span>
              <span>费用 ￥
                <span v-show="operateDetail.carrierName === null && operateDetail.sysRecommend">{{
                  operateDetail.sysRecommend?.cost }}</span>
                <span v-show="operateDetail.carrierName !== null">{{ operateDetail.transportFee }}</span>
              </span>
            </div>
            <div v-show="!operateDetail.sysRecommend && !operateDetail.truckStatus">暂无</div>
            <div style="display: flex;align-items:center" v-show="operateDetail.truckStatus === null">
              <span v-show="operateDetail.sysRecommend">如和承运商达成一致，点击
                <a-popconfirm ok-text="确认" cancel-text="取消" @confirm="confirm(operateDetail.executeType?.value)" @cancel="cancel">
                  <template #title>
                    <div style="padding-right:10px;width:130px">
                      <div>预计发车时间</div>
                      <a-date-picker :allowClear="false" :disabled-date="disabledDate" style="width:100%" v-model:value="arrangeLoadTimeStr" />
                    </div>
                  </template>
                  <a-button type="dashed" size="small" :loading="btnLoading">确认</a-button>
                </a-popconfirm>
                如未能达成点击
              </span>
              <span v-show="!operateDetail.sysRecommend">该线路没有系统推荐承运商，请点击</span>
              <a @click="choosePlant">人工选择</a>
            </div>
            <div style="display: flex;color:#333;" v-show="operateDetail.truckStatus">
              <span style="margin-right:5px">{{ operateDetail.truckType?.label }}</span>
              <span style="margin-right:5px">{{ operateDetail.carNo }}</span>
              <span style="margin-right:5px">({{ operateDetail.gpsType?.label }})</span>
              <span style="margin-right:5px" v-show="operateDetail.truckType?.value !== 3">{{ operateDetail.driverName
              }}</span>
              <span>{{ operateDetail.driverTel }}</span>
            </div>
          </div>
          <div style="margin-top:10px" v-show="operateDetail.truckStatus?.value >= 0">
            <!-- <a-button type="dashed" :loading="btnLoading"
              v-show="operateDetail.orderVehicleTransType === -1 || operateDetail.orderVehicleTransType === 0"
              @click="operationDetailShow = true">安排提车</a-button> -->
            <a-popconfirm title="确认取消装车？" ok-text="确认" cancel-text="关闭" @confirm="cancelLoadVeh">
              <a-button type="dashed" v-show="operateDetail.orderVehicleTransType === 1">取消发运</a-button>
            </a-popconfirm>
            <!-- <a-button type="dashed" @click="changePlanShow = true"
            v-show="operateDetail.orderVehicleTransType !== 2">修改方案</a-button> -->
            <a-button type="dashed" :loading="btnLoading" v-show="operateDetail.orderVehicleTransType === 1" @click="chooseBigCarShow">{{ operateDetail.driverTel !== '' && operateDetail.driverTel !== null ? '修改安排'
                : '安排提车' }}</a-button>
            <!-- <a-button type="dashed" v-show="operateDetail.orderVehicleTransType === 1"
            @click="deiverShow = true">更换地跑运输</a-button> -->
            <a-button type="dashed" :loading="btnLoading" v-show="operateDetail.orderVehicleTransType === 1 && operateDetail.isChoose?.value === 1" @click="receivables = true">发车</a-button>
            <a-button type="dashed" :loading="btnLoading" v-show="operateDetail.orderVehicleTransType === 2" @click="confirmArrival">
              <span>提车完成</span>
              <span v-show="operateDetail.transportType?.value === 3">送车完成</span>
            </a-button>
          </div>
        </div>
      </div>

      <div class="operate-div" style="width: 100%;" v-show="operateDetail.executeType?.value === 2">
        <div class="operate-title" style="display:flex;justify-content:space-between">
          <span>干线发运</span>
          <a @click="addReportShow = true, abnormalTask = operateDetail.taskId">异常上报</a>
        </div>
        <div class="operate-content">
          <div style="display:flex;justify-content:space-between;color:#344563;font-size: 12px;">
            <div>发运线路：{{ operateDetail.startAddress + '——' + operateDetail.endAddress }}</div>
          </div>
          <a-divider />
          <div style="display: flex;justify-content: space-between;margin-bottom:10px">
            <span v-show="!operateDetail.carrierName">
              <span style="color:#0066CC;margin-right:10px">系统推荐方案</span>
              <span style="color:#6B778C" v-show="operateDetail.sysRecommend">
                {{ operateDetail.sysRecommend?.startAddress?.details }}
                <span v-show="operateDetail.sysRecommend?.startAddress">——</span>
                {{ operateDetail.sysRecommend?.endAddress?.details }}
              </span>
            </span>
            <span style="color:#0066CC" v-show="operateDetail.carrierName">已选择方案</span>
            <div style="width:206px;color:#6B778C;text-align: right;">
              <span v-show="!operateDetail.truckStatus"> 请发送委托和承运商确认
                <ArrowRightOutlined style="font-size: 12px;width:20px" />
              </span>
              <a style="color:#0066CC" @click="getEntrust">委托</a>
            </div>
          </div>
          <div style="display:flex;justify-content: space-between;font-size: 12px;color:#6B778C">
            <div v-show="!operateDetail.sysRecommend && !operateDetail.truckStatus">暂无</div>
            <div v-show="operateDetail.sysRecommend || operateDetail.truckStatus">
              <span style="margin-right:15px" v-show="!operateDetail.truckStatus">{{
                operateDetail.sysRecommend?.carrierName }}</span>
              <span style="margin-right:15px" v-show="operateDetail.truckStatus">{{ operateDetail.carrierName }}</span>
              <span>费用 ￥
                <span v-show="operateDetail.carrierName === null && operateDetail.sysRecommend">{{
                  operateDetail.sysRecommend?.cost }}</span>
                <span v-show="operateDetail.carrierName !== null">{{ operateDetail.transportFee }}</span>
              </span>
            </div>
            <div style="display: flex;align-items:center" v-show="operateDetail.truckStatus === null">
              <span v-show="operateDetail.sysRecommend">如和承运商达成一致，点击
                <a-popconfirm ok-text="确认" cancel-text="取消" @confirm="confirm(operateDetail.executeType?.value)" @cancel="cancel">
                  <template #title>
                    <div style="padding-right:10px;width:130px">
                      <div>预计发车时间</div>
                      <a-date-picker :allowClear="false" :disabled-date="disabledDate" style="width:100%" v-model:value="arrangeLoadTimeStr" />
                    </div>
                  </template>
                  <a-button type="dashed" size="small" :loading="btnLoading">确认</a-button>
                </a-popconfirm>
                如未能达成点击
              </span>
              <span v-show="!operateDetail.sysRecommend">该线路没有系统推荐承运商，请点击</span>
              <a @click="choosePlant">人工选择</a>
            </div>
            <div style="display: flex;color:#333;" v-show="operateDetail.truckStatus">
              <span style="margin-right:5px" v-show="operateDetail.truckType">{{ operateDetail.truckType?.label }}</span>
              <span style="margin-right:5px">{{ operateDetail.carNo }}</span>
              <span style="margin-right:5px">{{ operateDetail.driverName }}</span>
              <span>{{ operateDetail.driverTel }}</span>
            </div>
          </div>
          <div style="margin-top:10px" v-show="operateDetail.truckStatus?.value >= 0">
            <!-- <a-button type="dashed" :loading="btnLoading"
              v-show="operateDetail.orderVehicleTransType === -1 || operateDetail.orderVehicleTransType === 0"
              @click="operationDetailShow = true">安排发运</a-button> -->
            <!-- <a-button type="dashed" @click="changePlanShow = true"
            v-show="operateDetail.orderVehicleTransType !== 2">修改方案</a-button> -->
            <a-popconfirm title="确认取消装车？" ok-text="确认" cancel-text="关闭" @confirm="cancelLoadVeh">
              <a-button type="dashed" :loading="btnLoading" v-show="operateDetail.orderVehicleTransType === 1">取消发运</a-button>
            </a-popconfirm>
            <a-button type="dashed" :loading="btnLoading" v-show="operateDetail.orderVehicleTransType === 1" @click="chooseBigCarShow">
              <span v-show="operateDetail.driverName">修改安排</span>
              <span v-show="operateDetail.driverName === '' || operateDetail.driverName === null">安排发运</span>
            </a-button>
            <!-- <a-button type="dashed" v-show="operateDetail.orderVehicleTransType === 1"
            @click="deiverShow = true">更换地跑运输</a-button> -->
            <a-button type="dashed" :loading="btnLoading" v-show="operateDetail.orderVehicleTransType === 1 && operateDetail.isChoose?.value === 1" @click="receivables = true">发车</a-button>
            <a-popconfirm ok-text="确定" cancel-text="取消" @confirm="confirmArrival">
              <template #icon></template>
              <template #title>
                <div>
                  <div style="text-align: center;">{{ `是否确认${operateDetail.transportType?.value === 2 ? '卸' : '提'}车` }}
                  </div>
                  <a-cascader :changeOnSelect="true" v-model:value="transitValue" :options="$store.state.app.cityDict" :show-search="{ filter }" placeholder="卸车地" @change="stopAddressChange" />
                </div>
              </template>
              <a-button type="dashed" :loading="btnLoading" v-show="operateDetail.orderVehicleTransType === 2">
                <span>运输完成</span>
                <span v-show="operateDetail.transportType?.value === 3">送车完成</span>
              </a-button>
            </a-popconfirm>
          </div>
        </div>
      </div>

      <div class="operate-div" style="width: 100%;" v-show="operateDetail.executeType?.value === 3">
        <div class="operate-title" style="display:flex;justify-content:space-between">
          <span>送车安排</span>
          <a @click="addReportShow = true, abnormalTask = operateDetail.taskId">异常上报</a>
        </div>
        <div class="operate-content">
          <div style="display:flex;justify-content:space-between;color:#344563;font-size: 12px;">
            <div>送车线路：{{ operateDetail?.startAddress }}——{{ operateDetail?.endAddress }}</div>
            <div>送车方式：{{ operateDetail.takeType?.label }}</div>
          </div>
          <a-divider />
          <div style="display: flex;justify-content: space-between;margin-bottom:10px">
            <span v-show="!operateDetail.carrierName">
              <span style="color:#0066CC;margin-right:10px">系统推荐方案</span>
              <span style="color:#6B778C" v-show="operateDetail.sysRecommend">
                {{ operateDetail.sysRecommend?.startAddress?.details }}
                <span v-show="operateDetail.sysRecommend?.startAddress">——</span>
                {{ operateDetail.sysRecommend?.endAddress?.details }}
              </span>
            </span>
            <span style="color:#0066CC" v-show="operateDetail.carrierName">已选择方案</span>
            <div style="width:206px;color:#6B778C;text-align: right;">
              <span v-show="!operateDetail.truckStatus"> 请发送委托和承运商确认
                <ArrowRightOutlined style="font-size: 12px;width:20px" />
              </span>
              <a style="color:#0066CC" @click="getEntrust">委托</a>
            </div>
          </div>
          <div style="display:flex;justify-content: space-between;font-size: 12px;color:#6B778C">
            <div>
              <span style="margin-right:15px" v-show="!operateDetail.truckStatus">{{
                operateDetail.sysRecommend?.carrierName
              }}</span>
              <span style="margin-right:15px" v-show="operateDetail.truckStatus">{{ operateDetail.carrierName }}</span>
              <span>费用 ￥
                <span v-show="operateDetail.carrierName === null && operateDetail.sysRecommend">{{
                  operateDetail.sysRecommend?.cost }}</span>
                <span v-show="operateDetail.carrierName !== null">{{ operateDetail.transportFee }}</span>
              </span>
            </div>
            <div style="display: flex;align-items:center" v-show="operateDetail.truckStatus === null">
              <span v-show="operateDetail.sysRecommend">如和承运商达成一致，点击
                <a-popconfirm ok-text="确认" cancel-text="取消" @confirm="confirm(operateDetail.executeType?.value)" @cancel="cancel">
                  <template #title>
                    <div style="padding-right:10px;width:130px">
                      <div>预计发车时间</div>
                      <a-date-picker :allowClear="false" :disabled-date="disabledDate" style="width:100%" v-model:value="arrangeLoadTimeStr" />
                    </div>
                  </template>
                  <a-button type="dashed" size="small" :loading="btnLoading">确认</a-button>
                </a-popconfirm>
                如未能达成点击
              </span>
              <span v-show="!operateDetail.sysRecommend">该线路没有系统推荐承运商，请点击</span>
              <a @click="choosePlant">人工选择</a>
            </div>
            <div style="display: flex;color:#333;" v-show="operateDetail.truckStatus">
              <span style="margin-right:5px" v-show="operateDetail.truckType">{{ operateDetail.truckType?.label }}</span>
              <span style="margin-right:5px" v-show="operateDetail.carNo">{{ operateDetail.carNo }}</span>
              <span style="margin-right:5px">{{ operateDetail.driverName }}</span>
              <span>{{ operateDetail.driverTel }}</span>
            </div>
          </div>
          <div style="margin-top:10px" v-show="operateDetail.truckStatus?.value >= 0">
            <a-popconfirm title="确认取消装车？" ok-text="确认" cancel-text="关闭" @confirm="cancelLoadVeh">
              <a-button type="dashed" :loading="btnLoading" v-show="operateDetail.orderVehicleTransType === 1">取消发运</a-button>
            </a-popconfirm>
            <a-button type="dashed" :loading="btnLoading" v-show="operateDetail.orderVehicleTransType === 1" @click="chooseBigCarShow">
              <span v-show="operateDetail.driverName">修改安排</span>
              <span v-show="operateDetail.driverName === '' || operateDetail.driverName === null">安排送车</span>
            </a-button>
            <a-button type="dashed" :loading="btnLoading" v-show="operateDetail.orderVehicleTransType === 1 && operateDetail.isChoose?.value === 1" @click="receivables = true">发车</a-button>
            <a-button type="dashed" :loading="btnLoading" v-show="operateDetail.orderVehicleTransType === 2" @click="confirmArrival">
              <span>送车完成</span>
            </a-button>
          </div>
        </div>
      </div>
    </div>
    <h2 style="display:flex;justify-content:center;align-items:center" v-show="operateDetail.executeStatus?.value === 9">
      所有任务已全部安排完成
    </h2>
  </div>
  <!-- 人工选择 -->
  <a-modal v-model:visible="carrierShow" width="40%" :bodyStyle="{ padding: '0 0 15px 15px' }" :maskClosable="false" @ok="confirmCarrier" @cancel="closeChange">
    <template #title>
      <div>
        <span v-show="operateDetail.executeType?.value === 1">人工选择-提车</span>
        <span v-show="operateDetail.executeType?.value === 2">人工选择-发运</span>
        <span v-show="operateDetail.executeType?.value === 3">人工选择-送车</span>
      </div>
    </template>
    <div>
      <div style="margin: 10px 0" v-show="operateDetail.executeType?.value === 2">
        <span>{{ operateDetail.startAddress }}——</span>
        <a-cascader v-if="carrierShow" :options="$store.state.app.cityTypeDict" :show-search="{ filter }" :changeOnSelect="true" @change="endAddressChange" placeholder="请选择终点" />
      </div>
      <div style="padding:5px;border:1px solid #eee">
        <NewCarrierMod :identicalCity="identicalCity" ref="carrierRef" v-if="carrierShow" :executeType="operateDetail.executeType?.value" :carrierId="carrierData.id" :type="1" @confirmCarrier="chooseCarrier" />
      </div>
      <div style="display:flex">
        <div>
          <div style="color:#344563;font-weight: 700;">运费：</div>
          <div class="input-div">
            <div class="input-data">
              <span>运费：</span>
              <a-input-number v-model:value="freeAmt.amt" :min="0" style="width:100px" />
            </div>
          </div>
        </div>
        <div>
          <div style="color:#344563;font-weight: 700;">预计发车时间：</div>
          <a-date-picker :disabled-date="disabledDate" :allowClear="false" style="width:140px" v-model:value="arrangeLoadTimeStr" />
        </div>
      </div>
    </div>
  </a-modal>
  <!-- 安排验车 -->
  <a-modal v-model:visible="arrangeShow" title="安排验车" width="40%" :destroyOnClose="true" @ok="getInspector">
    <div>
      <Inspector :taskId="monitorData.taskId" :truckStatus="operateDetail.truckStatus" ref="arrangeInfo" :carrier="carrierData" :appCarrier="1" />
    </div>
  </a-modal>
  <!-- 委托 -->
  <a-modal v-model:visible="entrustShow" footer="" :bodyStyle="{ padding: '10px' }">
    <template #title>
      <div>
        <span v-show="operateDetail.executeType?.value === 0">验车委托</span>
        <span v-show="operateDetail.executeType?.value === 1">取车委托</span>
        <span v-show="operateDetail.executeType?.value === 2">运车委托</span>
        <span v-show="operateDetail.executeType?.value === 3">送车委托</span>
      </div>
    </template>
    <div v-show="operateDetail.executeType?.value === 0">
      <p class="font-color2">订单号：{{dispatchDetail.orderId}}</p>
      <p class="font-color2">车牌/车架号：{{ dispatchDetail.brand + dispatchDetail.model }} {{ dispatchDetail.vinNo }}</p>
      <p class="font-color2">交车人姓名：{{ dispatchDetail.consignor }}</p>
      <p class="font-color2">交车人电话：{{ dispatchDetail.consignorMobile }}</p>
      <p class="font-color2">交车人身份证：{{ dispatchDetail.consignorCertificateNumber }}</p>
      <p class="font-color2">预约验车时间：{{ operateDetail.subscribeTime }}</p>
      <p class="font-color2">验车地址：{{ dispatchDetail.isPlaceIn?.value === 0 ? dispatchDetail.startAddress :
        dispatchDetail.placeInAddress }}</p>
      <div style="text-align:right">
        <a-button type="dashed" @click="copyData(operateDetail.executeType?.value)">一键复制</a-button>
      </div>
    </div>
    <div v-show="operateDetail.executeType?.value === 1">
      <p class="font-color2">订单号：{{dispatchDetail.orderId}}</p>
      <p class="font-color2">车牌/车架号：{{ dispatchDetail.brand + dispatchDetail.model }} {{ dispatchDetail.vinNo }}</p>
      <p class="font-color2">交车人姓名：{{ dispatchDetail.consignor }}</p>
      <p class="font-color2">交车人电话：{{ dispatchDetail.consignorMobile }}</p>
      <p class="font-color2">交车人身份证：{{ dispatchDetail.consignorCertificateNumber }}</p>
      <p class="font-color2" v-if="dispatchDetail.orderRemark">订单备注：{{ dispatchDetail.orderRemark }}</p>
      <p class="font-color2" v-if="dispatchDetail.remark">小车备注：{{ dispatchDetail.remark }}</p>
      <p class="font-color2">取车信息：{{ dispatchDetail.pickType ? dispatchDetail.pickType.label : '' }}&nbsp;{{
                dispatchDetail.placeInAddress }}</p>
      <p class="font-color2">温馨提示：物流只负责外观内饰的检查，车辆性能及其它内部问题我们没有技术能力检验，望理解！贵重物品不随车，小件物品请打包放好，有ETC请提前拔掉，谢谢配合！</p>
      <div style="text-align:right">
        <a-button type="dashed" @click="copyData(operateDetail.executeType?.value)">一键复制</a-button>
      </div>
    </div>
    <div v-show="operateDetail.executeType?.value === 2">
      <p class="font-color2">订单号：{{dispatchDetail.orderId}}</p>
      <p class="font-color2">车牌/车架号：{{ dispatchDetail.brand + dispatchDetail.model }} {{ dispatchDetail.vinNo }}</p>
      <p class="font-color2">起运地：{{dispatchDetail.startAddress}}</p>
      <span v-if="dispatchDetail.isNoticeSalesman?.value === 1">
        <p class="font-color2">目的地：{{dispatchDetail.endAddress}} {{ dispatchDetail.sourceMan }} {{ dispatchDetail.salesmanMobile }}</p>
      </span>
      <span v-else>
        <p class="font-color2">目的地：{{dispatchDetail.endAddress}} {{ dispatchDetail.picker }} {{ dispatchDetail.pickerMobile }}</p>
      </span>

      <p class="font-color2"><span v-show="dispatchDetail.paymentArrival > 0">
          订单到付: <a-tag color="red">{{ dispatchDetail.paymentArrival }}</a-tag>
          <span>{{ dispatchDetail.isNoticeReleaseCar?.value === 1 ? '等通知放车' : '' }}</span></span></p>
      <p class="font-color2">温馨提示：承运商只做交车相关的沟通，若客户询问其它，让联系业务咨询。有私自添加客户微信等撬客户行为，一经发现，终止合作！</p>
      <div style="text-align:right">
        <a-button type="dashed" @click="copyData(operateDetail.executeType?.value)">一键复制</a-button>
      </div>
    </div>
    <div v-show="operateDetail.executeType?.value === 3">
      <p class="font-color2">订单号：{{dispatchDetail.orderId}}</p>
      <p class="font-color2">车牌/车架号：{{ dispatchDetail.brand + dispatchDetail.model }} {{ dispatchDetail.vinNo }}</p>
      <span v-if="dispatchDetail.isNoticeSalesman?.value === 1">
        <p class="font-color2">收车人姓名：{{ dispatchDetail.sourceMan }}</p>
        <p class="font-color2" v-if="dispatchDetail.pushTime">收车人电话：{{ dispatchDetail.salesmanMobile }}</p>
      </span>
      <span v-else>
        <p class="font-color2">收车人姓名：{{ dispatchDetail.picker }}</p>
        <p class="font-color2" v-if="dispatchDetail.pushTime">收车人电话：{{ dispatchDetail.pickerMobile }}</p>
      </span>
      <p class="font-color2">收车人身份证：{{ dispatchDetail.pickerCertificateNumber }}</p>
      <p class="font-color2"><span v-show="dispatchDetail.paymentArrival > 0">
          订单到付：<a-tag color="red">{{ dispatchDetail.paymentArrival }}</a-tag></span>
        <span>{{ dispatchDetail.isNoticeReleaseCar?.value === 1 ? '等通知放车' : '' }}</span>
      </p>
      <p class="font-color2" v-if="dispatchDetail.orderRemark ">订单备注：{{ dispatchDetail.orderRemark }}</p>
      <p class="font-color2" v-if="dispatchDetail.remark">小车备注：{{ dispatchDetail.remark }}</p>
      <p class="font-color2">送车信息：{{ dispatchDetail.takeType ? dispatchDetail.takeType.label : '' }}
        <span v-if="dispatchDetail.takeOutAddress">&nbsp;{{ dispatchDetail.takeOutAddress }}</span>
      </p>
      <p class="font-color2">温馨提示：承运商只做交车相关的沟通，若客户询问其它，让联系业务咨询。有私自添加客户微信等撬客户行为，一经发现，终止合作！</p>
      <div style="text-align:right">
        <a-button type="dashed" @click="copyData(operateDetail.executeType?.value)">一键复制</a-button>
      </div>
    </div>
  </a-modal>
  <!-- 放空 -->
  <a-modal v-model:visible="nullVisible" title="填写放空费" @ok="nullBtn" :confirmLoading="btnLoading" :destroyOnClose="true">
    <p> 放空费：
      <a-input v-model:value="nullMoney" type="number" style="width:30%" />元
    </p>
    <span style="color:#f00">注：放空费的产生</span>
  </a-modal>
  <!-- 小程序码 -->
  <a-modal v-model:visible="QRCshow" title="小程序码" footer="">
    <div v-if="QRCdata">
      <div>
        <div>车架号：<a-tag color="green">{{ QRCdata.vinNo }}</a-tag></div>
        <div>线路：<span style="color:#f00"><span v-if="dispatchDetail.placeInAddress">{{ dispatchDetail.placeInAddress }}-</span>{{dispatchDetail.startAddress  }}</span>
        </div>
        <img v-show="!QRCloading" class="img" :src="QRCurl" style="width:100%;height:100%" />
          <div v-show="!QRCloading" class="flex ju-center m-t1 " style="color:red">验车照片请上传原图</div>
        <div style="text-align: center"><a-spin v-show="QRCloading" size="large" /></div>
      </div>
    </div>
    <div v-else>
      <div>
        <div>车架号：<a-tag color="green">{{ detailData.vinNo }}</a-tag></div>
        <div>线路：<span style="color:#f00">{{ detailData.startAddress + '——' + detailData.endAddress }}</span>
        </div>
        <img v-show="!QRCloading" class="img" :src="QRCurl" style="width:100%;height:100%" />
        <div v-show="!QRCloading" class="flex ju-center m-t1" style="color:red">验车照片请上传原图</div>
        <div style="text-align: center"><a-spin v-show="QRCloading" size="large" /></div>
      </div>
    </div>
  </a-modal>
  <!-- 验车凭证 -->
  <a-modal v-model:visible="evidenceShow" title="凭证信息" footer="" width="350px">
    <div class="evidenceDOM">
      <!-- <p><img class="img" src="@/assets/images/log.png" style="width:50%;height:100%" /></p> -->
      <p class="font-color2">司机姓名：{{ operateDetail?.driverName }}</p>
      <p class="font-color2"></p>
      <p class="font-color2">联系电话：{{ operateDetail?.driverTel }}</p>
      <p class="font-color2"></p>
      <p class="font-color2">身份证号：{{ operateDetail?.driverIdNumber }}</p>
    </div>
    <template #footer>
      <!-- <a-button @click="arrangeShow = true, evidenceShow = false">修改验车人</a-button> -->
    </template>
  </a-modal>
  <!-- 验车费用 -->
  <a-modal v-model:visible="inspectorFeeShow" title="验车费用" @ok="confirmInspection" :confirmLoading="btnLoading">
    <div>
      金额：<a-input v-model:value="amt" type="number" :min="0" />
    </div>
  </a-modal>
  <!-- 干线发运 -->
  <a-modal width="50%" v-model:visible="arrangeTransShow" title="安排发运">
    <div class="content-data">
      <BigcarChoose :carrierId="operateDetail.sysRecommend?.carrierId || operateDetail.carrierId" @carDriverData="getBigCarData" />
    </div>
  </a-modal>
  <!-- 取运送新增 -->
  <a-modal v-model:visible="operationDetailShow" width="50%" footer="" :destroyOnClose="true" :confirmLoading="btnLoading" @cancel="operateData" :maskClosable="false">
    <template #title>
      <div style="display:flex;justify-content: space-between;">
        <div style="display:flex">
          <div class="avater">
            <FileZipOutlined />
          </div>
          <div style="color: #344563">&emsp;<span>{{ operateDetail.vinNo }}&emsp;</span>
          </div>
          <a class="fs12 mlg-15" @click="copyText(operateDetail.vinNo)">复制</a>
        </div>
      </div>
    </template>
    <div class="content-data">
      <BigcarChoose :carrierId="operateDetail.sysRecommend?.carrierId || operateDetail.carrierId" @carDriverData="getBigCarData" />
    </div>
    <!-- <OperationDetail v-if="detailData.orderId" :vehiclesArr="[detailData]" /> -->
  </a-modal>
  <!-- 修改方案 -->
  <a-modal v-model:visible="changePlanShow" title="修改方案">
    <div>
      <a-button @click="choosePlant">承运商修改</a-button>
      <a-button @click="reachChooseShow = true">提车到达地址修改</a-button>
    </div>
  </a-modal>
  <!-- 输入实际收款方式 -->
  <a-modal v-model:visible="receivables" title="填写收款方式" @ok="startCar" :confirmLoading="btnLoading">
    <h1 v-show="operateDetail.isChoose?.value === 1">总金额<span style="color:#f00">{{ operateDetail.transportFee }}</span>
    </h1>
    <a-input v-if="bigCarData.truckStatus?.value !== 2" addon-before="油卡" v-model:value="driver.oilCard" type='number' size="large" :min="0">
      <template #suffix v-show="operateDetail.isChoose?.value === 1">
        <a-tooltip title="全部油卡">
          <EnterOutlined @click="driver.oilCard = operateDetail.transportFee, driver.bankCard = 0" style="color: rgba(0, 0, 0, 0.45)" />
        </a-tooltip>
      </template>
    </a-input>
    <a-divider type="vertical" />
    <a-input addon-before="转账" v-model:value="driver.bankCard" type='number' size="large" :min="0">
      <template #suffix v-show="operateDetail.isChoose?.value === 1">
        <a-tooltip title="全部转账">
          <EnterOutlined @click="driver.bankCard = operateDetail.transportFee, driver.oilCard = 0" style="color: rgba(0, 0, 0, 0.45)" />
        </a-tooltip>
      </template>
    </a-input>
  </a-modal>
  <!-- 更换地跑运输-选择司机 -->
  <a-modal width="50%" v-model:visible="deiverShow" title="选择司机" @ok="deiverShow = false">
    <Driver ref="driverRef" :carrierId="operateDetail.sysRecommend?.carrierId" @onondriverName="chooseDriver" />
  </a-modal>
  <!-- 选择大车或司机 -->
  <a-modal v-model:visible="changeBigShow" width="50%" title="选择大车或司机" @ok="confirmBigCar" :confirmLoading="btnLoading">
    <div>
      <BigcarChoose ref="bigCarChooseRef" :carrierId="operateDetail.sysRecommend?.carrierId || operateDetail.carrierId" @carDriverData="getBigCarData" />
    </div>
  </a-modal>
  <!-- 中途补位 -->
  <a-modal v-model:visible="priceShow" title="中途补位" :confirmLoading="confirmLoading" :destroyOnClose="true" @ok="confirmHalfway">
    <div>
      <!-- 费用：<a-input v-model:value="freight" type='number' :min="0" size="large" />
      <a-divider type="vertical" /> -->
      <a-cascader style="width:100%" :options="$store.state.app.cityTypeDict" :show-search="{ filter }" :changeOnSelect="true" @change="stopAddressChange" placeholder="请选择中转地点" />
      <div style="display: flex;align-items:center;margin-top:10px">
        是否门店：
        <a-select v-model:value="isShop" style="width: 120px" placeholder="是否门店">
          <a-select-option :value="1">是</a-select-option>
          <a-select-option :value="0">否</a-select-option>
        </a-select>
      </div>
      <a-divider type="vertical" />
      <div>
        <!-- <h1 style="text-align: center">总金额:<span style="color:#f00">{{ freight * 1 + '' }}</span></h1> -->
        <a-input addon-before="油卡" v-model:value="driver.oilCard" :min="0" type='number' size="large">
          <template #suffix>
            <a-tooltip title="全部油卡">
              <EnterOutlined @click="driver.oilCard = freight, driver.bankCard = 0" style="color: rgba(0, 0, 0, 0.45)" />
            </a-tooltip>
          </template>
        </a-input>
        <a-divider type="vertical" />
        <a-input addon-before="转账" v-model:value="driver.bankCard" :min="0" type='number' size="large">
          <template #suffix>
            <a-tooltip title="全部转账">
              <EnterOutlined @click="driver.bankCard = freight, driver.oilCard = 0" style="color: rgba(0, 0, 0, 0.45)" />
            </a-tooltip>
          </template>
        </a-input>
      </div>
    </div>
  </a-modal>
  <!-- 安排中-装车 -->
  <a-modal v-model:visible="arrangeingShow" title="安排中装车-中转地选择" @ok="arrangeLoadVeh" :confirmLoading="btnLoading">
    <div>
      <a-cascader style="width:100%" :options="$store.state.app.cityTypeDict" :show-search="{ filter }" :changeOnSelect="true" @change="stopAddressChange" placeholder="请选择中转地点" />
      <div style="display:flex;align-items:center;margin-top:10px">
        是否门店：
        <a-select v-model:value="isShop" style="width: 120px" placeholder="是否门店">
          <a-select-option :value="1">是</a-select-option>
          <a-select-option :value="0">否</a-select-option>
        </a-select>
      </div>
    </div>
  </a-modal>
  <!--修改承运商-->
  <a-modal v-model:visible="carrierChangeShow" title="修改承运商" width="50%" :destroyOnClose="true">
    <div>
      <CarrierList @carrier="carrierChange" />
    </div>
  </a-modal>
  <!-- 修改金额 -->
  <a-modal v-model:visible="editShow" title="修改内容" :destroyOnClose="true" @ok="confirmEdit" :confirmLoading="btnLoading" @cancel="closeEditShow">
    金额：
    <a-input v-model:value="money" type="number" />
    <a-divider />
    备注：
    <a-textarea :maxlength="200" v-model:value="reason" placeholder="请说明您要修改的原因" />
  </a-modal>
  <!-- 验车图片 -->
  <InspectionImg ref="imgListRef" :orderVehicleId="detailData.orderVehicleId" :loadInspectList="[]" />
  <!-- 异常上报 -->
  <AddReport ref="addReportRef" :windowShow="addReportShow" :vehicleDetail="detailData" @reportData="addReportData" />
  <!-- 选择提车到达地址 -->
  <ReachAddress :windowShow="reachChooseShow" :operateDetail="operateDetail" @address="changeEndAddress" />
  <!-- 派车图片查看 -->
  <VehicleImg ref="vehicleImgRef" />
  <!-- 大车操作 -->
  <LoadingVehilce :windowShow="loadingVehShow" :transTruckId="CarNoInfo.transTruckId" @statusShow="loadingVehShow = false" />

  <!-- 结算信息 -->
  <a-modal v-model:visible="settlementShow" :width="400" footer="" :destroyOnClose="true">
    <div class="m-b1">
      <span class="order-data-title">账单号：</span>
      <span class="font-color2" style="display: inline-block;width: 50%">
        <a-typography-paragraph :copyable="{ text: settlementDetail?.id }">
          {{ settlementDetail?.id }}
        </a-typography-paragraph>
      </span>
    </div>
    <p :span="12">
      <span class="order-data-title">客户名称：</span>
      <span class="font-color2">{{ settlementDetail?.customerName }}</span>
    </p>
    <p span="12">
      <span class="order-data-title">对账人：</span>
      <span class="font-color2">{{ settlementDetail?.sponsor }}</span>
    </p>
    <p :span="12">
      <span class="order-data-title">对账时间：</span>
      <span class="font-color2">{{ settlementDetail?.createTime }}</span>
    </p>
  </a-modal>
</template>

<script>
import { reactive, toRefs, ref, onMounted, watch } from 'vue'
import { ArrowRightOutlined, FileZipOutlined, EnterOutlined } from '@ant-design/icons-vue'
import { getExecute, arrangeTrnas, changeTransType, loadVehicle, carUnloadVehicle, arriveVehicle, vehDepart, addReport, getIsPushOrder, coverPit, plantPit, vehicleTaskCancel, vehicleTaskInfo, taskDeliveryPic } from '@/api/transport/transport'
import { vehicleDetail, depart, changeCarrier, changeDriver, setAssembled, transPlan, vehicleSettlement } from '@/api/transport/truck'
import { vent, done, QRCurl, inspectionChange, inspectionChangeEdit } from '@/api/transport/inspection'
import { transEdit } from '@/api/trans/ops/edit'
import { copyText, transitionTimeYMD } from '@/utils/util'
import { message } from 'ant-design-vue'
import NewCarrierMod from '../comon/newCarrierMod.vue'
import Inspector from '@/views/components/inspector'
import AddReport from '../comon/addReport.vue'
import LineChoose from '../comon/lineChoose.vue'
import PassingPoint from '../comon/passingPoint.vue'
import BigcarChoose from '@/views/components/bigCarChoose'
import ReachAddress from '../comon/reachAddress.vue'
import OperationDetail from '../../../transportCapacity/transportUse/detail/operationDetail.vue'
import Driver from '@/views/components/driver'
import InspectionImg from '@/views/components/vehicleUpload/inspectionImg'
import CarrierList from '@/views/transportCapacity/transportUse/comon/carrierList'
import DetailsModal from '../taskAssignment/detailsModal'
import VehicleImg from '@/views/components/vehicleImg'
import VehicleGps from '@/views/order/positionInfo/carGps.vue'
import PictureViewer from '@/components/PictureViewer'
import { optimalPageDetails } from '@/api/carrier/optimalLine'
import moment from 'moment'
import LoadingVehilce from '@/views/components/transport/bigVehicleOperate/loadingVehilce.vue'

export default {
  props: {
    taskDetail: Object,
    detail: Object,
    transList: Array,
    dispatchDetail: Object,
  },
  components: {
    NewCarrierMod,
    Inspector,
    EnterOutlined,
    AddReport,
    LineChoose,
    CarrierList,
    PassingPoint,
    BigcarChoose,
    ReachAddress,
    InspectionImg,
    FileZipOutlined,
    ArrowRightOutlined,
    OperationDetail,
    Driver,
    DetailsModal,
    VehicleImg,
    VehicleGps,
    PictureViewer,
    LoadingVehilce
  },
  setup (props, context) {
    // 禁用时间
    const disabledDate = current => {
      return current && current < moment().subtract(1, "days");
    }
    const visibleLookDeliver = ref(false)
    const lookImgID = ref([])
    const detailsShow = ref(false)
    const addReportRef = ref(null)
    const bigCarChooseRef = ref(null)
    const imgListRef = ref(null)
    const carrierRef = ref(null)
    const lookID = ref('')
    const vehicleGps = ref(null)
    const state = reactive({
      loadingVehShow: false,
      CarNoInfo: {},
      identicalCity: false,
      arrangeLoadTimeStr: '',//预计发车时间
      vehicleImgRef: null,
      orderInfo: [],
      carrierShow: false,
      carrierChangeShow: false,
      settlementShow: false,
      arrangeShow: false,
      entrustShow: false,
      btnLoading: false,
      priceShow: false, // 中途补位弹窗
      arrangeingShow: false, // 安排中装车
      inspectorFeeShow: false,
      changeBigShow: false,
      deiverShow: false,
      lineShow: false,
      pointShow: false,
      receivables: false,
      changePlanShow: false,
      operationDetailShow: false,
      reachChooseShow: false,
      arrangeTransShow: false,
      evidenceShow: false,
      addReportShow: false,
      nullVisible: false,
      QRCloading: false,
      QRCshow: false,
      editShow: false,
      isShop: 0,
      type: 0,
      amt: 0, // 验车费用字段
      nullMoney: 0,
      freight: 0, // 中途补位单台车运费
      QRCurl: '',
      money: null,
      reason: '',
      orderId: '',
      confirmTitle: '',
      abnormalTask: '',
      repealRemark: '',
      dockingPerson: '',
      dockingMobile: '',
      driver: {
        oilCard: 0,
        bankCard: 0,
      },
      transitValue: [],
      transList: [], // 发运模式list
      settlementDetail: {},
      transit: {},
      record: {},
      vehDetail: {},
      detailData: {},
      taskDetail: {},
      monitorData: {},
      carrierData: {},
      operateDetail: {},
      QRCdata: {},
      transitAddress: {}, // 中转地址
      inspectionData: {}, // 验车司机选择回传数据
      carInfo: {},// 验车车辆选择回传数据
      lineData: {}, // 线路选择数据
      bigCarData: {}, // 选择大车和司机
      dispatchDetail: {}, // 任务详情
      passingPointData: [], // 途经点
      transColumns: [
        {
          title: '大车车牌',
          dataIndex: 'carNo',
          width: '5%',
          slots: {
            customRender: 'carNo'
          }
        },
        {
          title: '运输类型',
          width: '4%',
          dataIndex: 'transportType.label'
        },
        {
          title: '承运商',
          width: '10%',
          slots: {
            customRender: 'carrierName'
          }
        },
        {
          title: '运费',
          dataIndex: 'transportFee',
          width: '4%',
          slots: {
            customRender: 'transportFee'
          }
        },
        {
          title: '运输状态',
          dataIndex: 'truckStatus.label',
          width: '4%'
        },
        {
          title: '运输路线',
          dataIndex: 'lineName',
          width: '12%'
        },
        {
          title: '对账结算',
          dataIndex: 'settlementStatus',
          width: '10%',
          slots: {
            customRender: 'settlementStatus'
          }
        },
        {
          title: '结算类型',
          width: '4%',
          slots: {
            customRender: 'orderSettlement'
          }
        },
        {
          title: '安排时间',
          dataIndex: 'loadingTime',
          width: '8%'
        },
        {
          title: '安排人',
          dataIndex: 'loadingOperator',
          width: '3%'
        },
        {
          title: '发车时间',
          dataIndex: 'departureTime',
          width: '8%'
        },
        {
          title: '发车人',
          dataIndex: 'departure',
          width: '3%'
        },
        {
          title: '卸车时间',
          dataIndex: 'unloadingTime',
          width: '7%'
        },
        {
          title: '卸车人',
          dataIndex: 'unloadingOperator',
          width: '3%'
        },
        {
          title: '卸车地',
          dataIndex: 'endAddress',
          width: '10%'
        },
        {
          title: '操作',
          width: '100px',
          align: 'center',
          fixed: 'right',
          slots: {
            customRender: 'repeal'
          }
        }
      ],
      freeAmt: {
        amt: null,
        oilAmt: null
      }
    })
    const columnsSend = ref([
      {
        title: '车牌车型',
        dataIndex: 'vinNo',
        align: 'center',
        slots: { customRender: 'vinNo' },
      },
      {
        title: '运费',
        dataIndex: 'totalAmt',
        align: 'center',
      },
      {
        title: '任务状态',
        dataIndex: 'taskStatus.label',
        align: 'center',
      },
      {
        title: '承运商',
        dataIndex: 'carrierName',
        align: 'center',
      },
      {
        title: '任务起点',
        dataIndex: 'lineStart',
        align: 'center',
      },
      {
        title: '任务终点',
        dataIndex: 'lineEnd',
        align: 'center',
      },
      {
        title: '结算状态',
        dataIndex: 'settlementStatus.label',
        align: 'center',
      },
      {
        title: '车辆状态',
        dataIndex: 'transStatus.label',
        align: 'center',
      },
      {
        title: '开始时间',
        dataIndex: 'taskStartTime',
        align: 'center',
      },
      {
        title: '结束时间',
        dataIndex: 'taskEndTime',
        align: 'center',
      },
      {
        title: '完成时效',
        dataIndex: 'finishPrescription',
        align: 'center',
      },
      {
        title: '照片',
        dataIndex: 'pic.label',
        slots: {
          customRender: 'image'
        },
        align: 'center',
      },
      {
        title: '位置',
        dataIndex: 'address',
        slots: { customRender: 'address' },
        align: 'center',
      },
      {
        title: '交车条',
        dataIndex: 'deliveryCard',
        slots: { customRender: 'deliveryCard' },
        align: 'center',
      },
      {
        title: '操作',
        dataIndex: 'add',
        slots: { customRender: 'add' },
        fixed: 'right',
        align: 'center',
      },
    ])
    const lookCarNo = (record) => {
      state.loadingVehShow = true
      state.CarNoInfo = record
    }
    const getData = () => {
      state.taskDetail = props.taskDetail
      state.detailData = props.detail
      state.orderId = state.detailData.orderId
      state.transList = props.transList
      setTimeout(() => {
        state.dispatchDetail = props.dispatchDetail
      }, 800)
      operateData()
    }

    const closeChange = () => {
      state.carrierData = {}
      state.freeAmt.amt = null
      state.freeAmt.oilAmt = null
    }
    const clearData = () => {
      state.transitAddress = {}
      state.isShop = 0
      state.transit = {}
      state.inspectionData = {}
      state.carInfo = {}
      state.bigCarData = {}
      state.transitValue = []
    }
    // 查看交车条
    const lookDeliveryCard = (record) => {
      taskDeliveryPic(record.orderVehicleId, {
        taskId: record.taskId
      }).then(res => {
        if (res.code !== 10000) return
        lookImgID.value = res.data.map(item => item.fileId)
        visibleLookDeliver.value = true
      })

    }
    // 撤销外派
    const revokeSend = () => {
      context.emit('loadData', true)
    }
    // 查看视频图片
    const lookVehicleImg = (record) => {
      setTimeout(() => {
        state.vehicleImgRef.vehicleImg(record.orderVehicleId, record.vinNo)
      }, 50)
    }
    const getEntrust = () => {
      state.entrustShow = true
      // state.entrustLoading = true
      // vehicleDetail(state.operateDetail.vehicleId)
      //   .then(res => {
      //     if (res.code === 10000) {
      //       state.vehDetail = res.data
      //       state.entrustLoading = false
      //       console.log('vehDetail', res.data);
      //     }
      //   })
    }
    // 获取任务信息-状态
    const operateData = () => {
      state.btnLoading = true
      getExecute(state.detailData.orderVehicleId).then(res => {
        if (res.code === 10000 && res.data) {
          state.operateDetail = res.data
          state.detailData.transOrderVehicleId = res.data.transOrderVehicleId
          state.detailData.truckStatus = state.operateDetail.truckStatus
          state.detailData.carrierId = state.operateDetail.carrierId
          state.detailData.carrierName = state.operateDetail.carrierName
          // if (!state.operateDetail.truckStatus) {
          //   state.detailData.carrierId = state.operateDetail.sysRecommend.carrierId
          //   state.detailData.carrierName = state.operateDetail.sysRecommend.carrierName
          // } else {
          //   state.detailData.carrierId = state.operateDetail.carrierId
          //   state.detailData.carrierName = state.operateDetail.carrierName
          // }
          state.carrierData = {
            orderId: state.detailData.orderId || state.orderId,
            chooseType: state.operateDetail.isSysRecommend?.value,
            carrierName: state.operateDetail.carrierName !== '' ? state.operateDetail.carrierName : state.operateDetail.sysRecommend?.carrierName,
            orderVehicleIds: [state.operateDetail.vehicleId]
          }
          context.emit('loadData', res.data.tovId)
          clearData()
          console.log(res.data);
          state.arrangeLoadTimeStr = new Date().toLocaleDateString()
          state.btnLoading = false
        } else state.operateDetail = {}
      }).finally(() => { state.btnLoading = false })
    }
    const taskOperation = (item) => {

      lookID.value = item.taskId
      detailsShow.value = true
    }

    const lookVehicleGps = (record) => {
      setTimeout(() => {
        vehicleGps.value.onvisible(true)
        vehicleGps.value.onorderVehicleId(record.orderVehicleId)
      }, 50)
    }
    const choosePlant = () => {
      state.btnLoading = true
      getIsPushOrder(state.detailData.orderId || state.orderId).then(res => {
        if (res.code === 10000) {
          if (res.data === true) {
            state.carrierShow = true
            state.identicalCity = false
          } else message.info('订单未推送')
        }
      }).finally(() => { state.btnLoading = false })

    }
    const chooseCarrier = e => {
      if (e) {
        state.carrierData = e
        if (e.id) state.carrierData.orderId = state.operateDetail.orderId
        // if (!state.operateDetail.sysRecommend) state.operateDetail.sysRecommend = {}
        // state.operateDetail.sysRecommend.carrierName = e.carrierName
        // state.operateDetail.sysRecommend.carrierId = e.id
        // state.operateDetail.sysRecommend.cost = state.freeAmt.amt
      }
    }
    const confirmCarrier = () => {
      if (state.freeAmt.amt !== null) {
        if (state.carrierData.id) {
          if (state.operateDetail.executeType?.value === 2) {
            console.log(state.transitAddress);
            if (state.transitAddress.city) loadVeh(1), state.carrierShow = false, state.freeAmt.amt = null
            else message.error('请选择目的地')
          } else loadVeh(1), state.carrierShow = false, state.freeAmt.amt = null
        } else message.error('请选择承运商')
      } else message.info('请填写运费')
    }
    const reportClick = () => {
      state.addReportShow = true
      state.abnormalTask = state.operateDetail.taskId
    }
    // 新增异常上报
    const addReportData = e => {
      if (e.reportType !== null && e.reportType !== undefined && e.reportType !== 0) {
        delete e.belong
        let submit = {
          ...e,
          totvId: state.operateDetail.totvId,
          orderVehicleId: state.operateDetail.vehicleId,
          taskId: state.abnormalTask,
        }
        addReport(submit).then(res => {
          if (res.code === 10000) {
            state.addReportShow = false
            message.success('上报成功')
          }
        })
      } else state.addReportShow = false
    }
    // 验车人回传接收
    const arrangeInfo = ref(null)
    const getInspector = () => {
      if (!arrangeInfo.value?.info.driverInfo?.carrierId) {
        message.error('请先选择承运商')
        return
      }
      state.inspectionData = arrangeInfo.value?.info.driverInfo
      state.carInfo = arrangeInfo.value?.info.carInfo
      state.inspectorFeeShow = true
      state.arrangeShow = false
    }
    // 放空
    const nullBtn = (item) => {
      if (state.nullMoney >= 0) {
        state.btnLoading = true
        vent({
          amt: state.nullMoney,
          orderId: state.detailData.orderId || state.orderId,
          orderVehicleId: state.operateDetail.vehicleId
        }).then(res => {
          if (res.code === 10000) {
            message.success('订单放空' + res.msg)
            state.nullVisible = false
            state.btnLoading = false
            context.emit('loadData', true) // 用于更新左侧组件列表数据
            operateData()
          }
        }).finally(() => { state.btnLoading = false, state.nullMoney = 0 })
      } else message.error('放空金额不能小于0')
    }
    // 生成小程序码
    const handleCope = (item, num) => {

      if (item) {
        state.QRCdata = item
        if (num === 1) {
          state.QRCshow = !state.QRCshow
          if (state.QRCshow === true) {
            state.QRCloading = true
            const wXprogramQRcode = { scene: item.vehicleId, type: 1 }
            QRCurl(wXprogramQRcode)
              .then(res => {
                if (res.code === 10000) {
                  state.QRCurl = 'data:image/png;base64,' + res.data
                  state.QRCloading = false
                }
              }).catch(err => console.log(err))
          }
        } else {
          state.QRCshow = true
          state.QRCdata = item
          if (state.QRCshow === true) {
            state.QRCloading = true
            const wXprogramQRcode = { scene: item.id, type: 1 }
            QRCurl(wXprogramQRcode)
              .then(res => {
                if (res.code === 10000) {
                  state.QRCurl = 'data:image/png;base64,' + res.data
                  state.QRCloading = false
                }
              }).catch(err => console.log(err))
          }
        }
      } else {
        state.QRCshow = !state.QRCshow
        state.QRCdata = state.operateDetail

        if (state.QRCshow === true) {
          state.QRCloading = true
          const wXprogramQRcode = { scene: state.operateDetail.vehicleId, type: 1 }
          QRCurl(wXprogramQRcode)
            .then(res => {
              if (res.code === 10000) {
                state.QRCurl = 'data:image/png;base64,' + res.data
                state.QRCloading = false
              }
            }).catch(err => console.log(err))
        }
      }
    }
    // 验车完成前提示确认框--判断验车照片的数量大于0
    const prompt = () => {
      // let num = state.monitorData.trans.reduce((t, v) => t + v.picNum, 0)
      if (state.operateDetail.picNum <= 0) state.confirmTitle = '检测到你还未上传过图片,确认完成吗?'
      else state.confirmTitle = '确认验车完成'
    }
    // 验车图片
    const updateImg = () => {
      setTimeout(() => {
        imgListRef.value.inspectionImg = true
        imgListRef.value.loadData(state.operateDetail.vehicleId)
      }, 100)
    }
    // 验车完成按钮
    const inspectorComplete = () => {
      state.btnLoading = true
      done(state.operateDetail.vehicleId, state.detailData.taskId || 0).then(res => {
        if (res.code === 10000) {
          message.success('验车' + res.msg)
          operateData()
          context.emit('loadData', true) // 用于更新左侧组件列表数据
        }
      }).finally(() => { state.btnLoading = false })
    }
    // 司机选择完成-金额填写后
    const confirmInspection = () => {
      state.btnLoading = true
      if (state.operateDetail.truckStatus) {
        inspectionChangeEdit({
          carrierTruckId: state.carInfo.id ? state.carInfo.id : '',
          carrierTruckNo: state.carInfo.carNo ? state.carInfo.carNo : '',
          amt: state.amt,
          orderVehicleId: state.operateDetail.vehicleId,
          carrierId: state.inspectionData.carrierId,
          carrierName: state.inspectionData.carrierName,
          driverId: state.inspectionData.id,
          driverName: state.inspectionData.name,
          driverType: state.inspectionData.driverType,
          orderId: state.detailData.orderId || state.orderId,
          driverTel: state.inspectionData.mobile,
          driverIdNumber: state.inspectionData.idNumber
        }).then(res => {
          if (res.code === 10000) {
            message.success('修改成功')
            context.emit('loadData', true) // 用于更新左侧组件列表数据
            operateData()
            clearData()
          }
        }).finally(() => { state.btnLoading = false, state.inspectorFeeShow = false, state.amt = 0 })
      } else {
        inspectionChange({
          carrierTruckId: state.carInfo.id ? state.carInfo.id : '',
          carrierTruckNo: state.carInfo.carNo ? state.carInfo.carNo : '',
          amt: state.amt,
          carrierId: state.inspectionData.carrierId,
          carrierName: state.inspectionData.carrierName,
          driverId: state.inspectionData.id,
          driverName: state.inspectionData.name,
          driverType: state.inspectionData.driverType,
          orderId: state.detailData.orderId || state.orderId,
          driverTel: state.inspectionData.mobile,
          driverIdNumber: state.inspectionData.idNumber,
          orderVehicleIds: [state.operateDetail.vehicleId]
        }).then(res => {
          if (res.code === 10000) {
            message.success('安排成功')
            context.emit('loadData', true) // 用于更新左侧组件列表数据
            operateData()
            clearData()
          }
        }).finally(() => { state.btnLoading = false, state.inspectorFeeShow = false })
      }
    }
    // 确认提车完成
    const takeCarComplet = () => {
      state.btnLoading = true
      take(state.monitorData.orderId).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          state.btnLoading = false
          operateData()
          clearData()
          context.emit('loadData', true) // 用于更新左侧组件列表数据
        }
      }).finally(() => { state.btnLoading = false })
    }
    // 线路选择数据回传接收
    const getLineData = e => {
      if (e) {
        state.lineData = e
        state.lineShow = false
      }
    }
    // 选择途经点
    const getPassingPointData = e => {
      if (e) {
        state.passingPointData = e
        state.pointShow = false
      }
    }
    // 取消装车
    const cancelLoadVeh = () => {
      state.btnLoading = true
      carUnloadVehicle({ ids: [state.operateDetail.totvId] })
        .then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            operateData()
            clearData()
            context.emit('loadData', true) // 用于更新左侧组件列表数据
          }
        }).finally(() => { state.btnLoading = false })
    }
    // 选择大车和司机 弹窗
    const chooseBigCarShow = () => {
      state.changeBigShow = true
      setTimeout(() => {
        bigCarChooseRef.value.loadData()
      }, 50)
    }
    // 选择大车和司机或地跑
    const getBigCarData = (e) => {
      if (e && state.operateDetail.truckStatus) {
        state.bigCarData = e
        if (e.truckStatus && e.truckStatus.value === 3) state.priceShow = true
        if (e.driver === true) {
          changeDriver(state.planId || state.operateDetail.transTruckId, state.bigCarData.id)
            .then(res => {
              if (res.code === 10000) {
                message.success(res.msg)
                operateData()
                state.changeBigShow = false
                setTimeout(() => {
                  state.bigCarData = {}
                  bigCarChooseRef.value.detail = {}
                  bigCarChooseRef.value.clearStatus()
                }, 50)
                clearData()
                context.emit('loadData', true) // 用于更新左侧组件列表数据
              }
            }).finally(() => { state.btnLoading = false })
        }
      }
    }
    // 确认大车、司机或地跑
    const confirmBigCar = () => {
      switch (state.bigCarData.truckStatus?.value) {
        case 1: // 空闲
          if (state.bigCarData.name) {
            if (state.bigCarData.carrierTruckId) { // 更换大车及司机
              state.btnLoading = true
              changeTransType(state.planId || state.operateDetail.transTruckId, state.bigCarData.carrierTruckId, state.bigCarData.id)
                .then(res => {
                  if (res.code === 10000) {
                    message.success(res.msg)
                    operateData()
                    state.changeBigShow = false
                    setTimeout(() => {
                      state.bigCarData = {}
                      bigCarChooseRef.value.detail = {}
                      bigCarChooseRef.value.clearStatus()
                    }, 50)
                    clearData()
                    context.emit('loadData', true) // 用于更新左侧组件列表数据
                  }
                }).finally(() => { state.btnLoading = false })
            }
          } else message.error('请选择司机')
          break;
        case 2: // 安排中
          state.receivables = true
          break;
        case 3: // 运输中
          state.receivables = true
          break;
      }
    }
    // 中途补位
    const confirmHalfway = () => {
      if (state.isShop === 1 || state.isShop === 0) {
        if (state.driver.oilCard * 1 + state.driver.bankCard * 1 > 0) {
          state.confirmLoading = true
          let submit = {
            ...state.driver,
            isShop: state.isShop,
            carrierId: state.bigCarData.carrierId,
            carrierTruckId: state.bigCarData.carrierTruckId,
            orderVehicleId: state.operateDetail.vehicleId,
            transOrderVehicleIds: [state.operateDetail.tovId],
            transTruckId: state.bigCarData.transTruckId,
            transit: state.transit
          }
          coverPit(submit).then(res => {
            if (res.code === 10000) {
              message.success(res.msg)
              state.priceShow = false
              state.changeBigShow = false
              state.transit = {}
              state.bigCarData = {}
              state.isShop = 0
              state.driver.oilAmt = 0
              state.driver.bankCard = 0
              operateData()
              context.emit('loadData', true) // 用于更新左侧组件列表数据
            }
          }).finally(() => { state.confirmLoading = false })
        } else message.error('金额不能为0')
      } else {
        message.error('请选择是否门店')
      }
    }
    // 安排装车发运任务
    const confirmTrans = () => {
      let submit = {
        carrierDriverId: state.bigCarData.id,
        carrierId: state.bigCarData.carrierId,
        carrierTruckId: state.bigCarData.carrierTruckId,
        freight: 0,
        lineId: state.lineData.id,
        passPoints: state.passingPointData,
        orderVehicleId: state.operateDetail.vehicleId,
        taskId: state.monitorData.taskId || null,
        transportType: { name: state.operateDetail.transportType.label, value: state.operateDetail.transportType.value }
      }
      state.btnLoading = true
      arrangeTrnas(submit).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          operateData()
          context.emit('loadData', true) // 用于更新左侧组件列表数据
          state.arrangeTransShow = false
        }
      }).finally(() => { state.btnLoading = false })
    }
    // 提车任务修改目的地地址
    const changeEndAddress = e => {
      if (e.addressDetails || e.dockingPerson) {
        if (!state.operateDetail.sysRecommend) state.operateDetail.sysRecommend = {}
        state.operateDetail.sysRecommend.carrierId = e.carrierId
        state.operateDetail.sysRecommend.carrierName = e.carrierName
        state.lineData.endAddress = e.address + e.addressDetails
        state.dockingPerson = e.dockingPerson
        state.dockingMobile = e.dockingMobile
        state.reachChooseShow = false
        loadVeh()
      } else state.reachChooseShow = false
    }

    // 确认请求
    const loadVeh = (num) => {
      let carrierData = {}
      if (state.carrierData.id) {
        carrierData.carrierName = state.carrierData.carrierName
        carrierData.carrierId = state.carrierData.carrierId ? state.carrierData.carrierId : state.carrierData.id
        carrierData.cost = state.freeAmt.amt
      } else {
        carrierData.carrierName = state.operateDetail.sysRecommend.carrierName
        carrierData.carrierId = state.operateDetail.sysRecommend.carrierId
        carrierData.cost = state.operateDetail.sysRecommend.cost
      }
      let submit = {
        carrierId: carrierData.carrierId,
        startAddress: state.operateDetail.sysRecommend ? state.operateDetail.sysRecommend.startAddress : null,
        endAddress: state.operateDetail.sysRecommend ? state.operateDetail.sysRecommend.endAddress : null,
        transOrderVehicleIds: [state.operateDetail.transOrderVehicleId],
        orderVehicleId: state.operateDetail.vehicleId,
        freight: state.freeAmt.amt,
        isShop: state.isShop,
        arrangeLoadTimeStr: transitionTimeYMD(state.arrangeLoadTimeStr),
        transportType: state.operateDetail.transportType,
        taskId: null,
        transit: state.transitAddress.city !== undefined ? state.transitAddress : state.operateDetail.sysRecommend ? state.operateDetail.sysRecommend.endAddress : null
      }
      state.btnLoading = true
      loadVehicle(submit).then(res => {
        if (res.code === 10000) {
          if (num) state.carrierShow = false
          setTimeout(() => {
            if (carrierRef.value) {
              carrierRef.value.chooseData = {}
              state.carrierData = {}
              state.transitAddress = {}
              state.freeAmt.amt = null
              state.isShop = 0
              carrierRef.value.carrierList = JSON.parse(carrierRef.value.mirrorCarrierList)
            }
          }, 200)
          operateData()
          let time = state.arrangeLoadTimeStr ? transitionTimeYMD(state.arrangeLoadTimeStr) : null
          context.emit('loadData', true, time, props.detail) // 用于更新左侧组件列表数据
          message.success(res.msg)
        } else operateData()
      }).finally(() => { state.btnLoading = false })
    }
    // 干线修改目的地
    const endAddressChange = (e, v) => {
      if (!e[1]) return
      if (e[1] == state.operateDetail.sysRecommend.startAddress.city) {
        carrierRef.value.chooseData = {}
        carrierRef.value.carrierList = []
        carrierRef.value.mirrorCarrierList = []
        state.identicalCity = true
        carrierRef.value.isSearch = false
        state.transitAddress.province = e[0]
        state.transitAddress.city = e[1]
        state.transitAddress.area = e[2]
        return
      }
      state.identicalCity = false
      carrierRef.value.loading = true
      optimalPageDetails({
        startProvince: state.operateDetail.sysRecommend.startAddress.province,
        startCity: state.operateDetail.sysRecommend.startAddress.city,
        startArea: state.operateDetail.sysRecommend.startAddress.area,
        endProvince: e[0],
        endCity: e[1],
        endArea: e[2],
      }).then(res => {
        if (res.code !== 10000) return
        carrierRef.value.isSearch = true
        carrierRef.value.chooseData = {}
        carrierRef.value.carrierList = res.data
        carrierRef.value.mirrorCarrierList = JSON.stringify(res.data)
      }).finally(() => { carrierRef.value.loading = false })
      state.transitAddress.province = e[0]
      state.transitAddress.city = e[1]
      state.transitAddress.area = e[2]

    }
    // 确认承运商委托按钮
    const confirm = (num) => {
      state.carrierData.chooseType = 1
      state.carrierData.carrierName = state.operateDetail.sysRecommend.carrierName
      state.freeAmt.amt = state.operateDetail.sysRecommend.cost
      switch (num) {
        case 0:
          state.arrangeShow = true
          break;
        case 1:
          // if (state.operateDetail.sysRecommend) loadVeh()
          if (state.isShop === 1 || state.isShop === 0) {
            state.btnLoading = true
            getIsPushOrder(state.detailData.orderId).then(res => {
              if (res.code === 10000) {
                if (res.data === true) loadVeh()
                else message.info('订单未推送')
              }
            }).finally(() => { state.btnLoading = false })
          } else message.error('请选择是否门店')
          break;
        case 2:
          if (state.isShop === 1 || state.isShop === 0) {
            if (state.operateDetail.sysRecommend) loadVeh()
            else message.info('当前任务暂无推荐信息，请选择人工')
          } else message.error('请选择是否门店')
          break;
        case 3:
          if (state.isShop === 1 || state.isShop === 0) {
            if (state.operateDetail.sysRecommend) loadVeh()
            else message.info('当前任务暂无推荐信息，请选择人工')
          } else message.error('请选择是否门店')
          break;
      }
    }
    // 更换地跑司机
    const chooseDriver = recode => {
      if (recode) {
        state.btnLoading = true
        changeDriver(state.operateDetail.transTruckId, recode.id)
          .then(res => {
            if (res.code === 10000) {
              state.deiverShow = false
              message.success(res.msg)
              operateData()
            }
          }).finally(() => { state.btnLoading = false })
      }
    }
    // 安排中装车
    const arrangeLoadVeh = () => {
      if (state.isShop === 1 || state.isShop === 0) {
        let submit = {
          freight: state.driver.bankCard,
          isShop: state.isShop,
          carrierDriverId: state.bigCarData.carrierDriverId,
          carrierId: state.bigCarData.carrierId,
          carrierTruckId: state.bigCarData.carrierTruckId,
          orderVehicleId: state.operateDetail.vehicleId,
          transOrderVehicleIds: [state.operateDetail.tovId],
          transTruckId: state.bigCarData.transTruckId,
          transit: state.transit
        }
        state.btnLoading = true
        plantPit(submit).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.arrangeingShow = false
            state.receivables = false
            state.changeBigShow = false
            state.transit = {}
            state.bigCarData = {}
            state.isShop = 0
            state.driver.oilAmt = 0
            state.driver.bankCard = 0
            operateData()
          }
        }).finally(() => { state.btnLoading = false })
      } else {
        message.error('请选择是否门店')
      }
    }
    // 发车/验车完成--安排中装车
    const startCar = () => {
      if (state.bigCarData.truckStatus?.value === 2) {
        state.arrangeingShow = true
      } else {
        if (state.operateDetail.executeType?.value === 0) {
          state.btnLoading = true
          done(state.operateDetail.vehicleId, state.detailData.taskId || 0).then(res => {
            if (res.code === 10000) {
              message.success('验车' + res.msg)
              operateData()
              context.emit('loadData', true)
            }
          }).finally(() => { state.btnLoading = false })
        } else {
          state.btnLoading = true
          vehDepart({
            transTruckId: state.planId || state.operateDetail.transTruckId,
            totvId: state.operateDetail.totvId,
            ...state.driver
          }).then(res => {
            if (res.code === 10000) {
              state.receivables = false
              state.driver.oilCard = 0
              state.driver.bankCard = 0
              message.success(res.msg)
              context.emit('loadData', true) // 用于更新左侧组件列表数据
              context.emit('departureSuccessful')
              operateData()
            }
          }).finally(() => { state.btnLoading = false })
        }
      }
    }
    const stopAddressChange = (e, v) => {
      state.transitValue = e
      state.transit.province = e[0]
      state.transit.city = e[1]
      state.transit.area = e[2]
    }
    // 到达-完成发运
    const confirmArrival = () => {
      // if (state.transit.area) {
      state.btnLoading = true
      arriveVehicle({
        transTruckId: state.planId || state.operateDetail.transTruckId,
        stopAddress: state.transit,
        totvId: state.operateDetail.totvId,
        transOrderVehicleIds: [state.operateDetail.transOrderVehicleId],
        transportType: state.operateDetail.transportType
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          state.receivables = false
          state.driver.oilCard = 0
          state.driver.bankCard = 0
          state.transitValue = []
          state.transit = {}
          operateData()
          context.emit('loadData', true) // 用于更新左侧组件列表数据
        }
      }).finally(() => { state.btnLoading = false })
      // } else message.error('请选择完整的省市区')
    }
    // 一键复制委托信息
    const copyData = type => {
      let detail = state.dispatchDetail
      switch (type) {
        case 0:
          copyText(
            `订单号：${detail.orderId}\n车牌/车架号：${detail.brand + detail.model + ' ' + detail.vinNo}\n交车人姓名：${detail?.consignor}\n交车人电话：${detail?.consignorMobile}\n交车人身份证：${detail?.consignorCertificateNumber}\n预约验车时间：${state.operateDetail.subscribeTime || ''}\n验车地址：${state.dispatchDetail.isPlaceIn?.value === 0 ? state.dispatchDetail.startAddress : state.dispatchDetail.placeInAddress}`
          )
          message.success('文本内容已复制')
          break;
        case 1:
          copyText(
            `订单号：${detail.orderId}\n车牌/车架号：${detail.brand + detail.model + ' ' + detail.vinNo}\n交车人姓名：${detail?.consignor}\n交车人电话：${detail?.consignorMobile}\n交车人身份证：${detail?.consignorCertificateNumber}\n订单备注：${detail?.orderRemark}\n小车备注：${detail?.remark}\n取车信息：${detail?.pickType?.label} ${detail.placeInAddress}\n温馨提示：物流只负责外观内饰的检查，车辆性能及其它内部问题我们没有技术能力检验，望理解！贵重物品不随车，小件物品请打包放好，有ETC请提前拔掉，谢谢配合！`
          )
          message.success('文本内容已复制')
          break;
        case 2:
          let str = detail.isNoticeSalesman?.value === 1 ? detail.sourceMan + detail.salesmanMobile : detail.picker + detail.pickerMobile
          copyText(
            `订单号：${detail.orderId}\n车牌/车架号：${detail.brand + detail.model + ' ' + detail.vinNo}\n起运地：${detail.startAddress}\n目的地：${detail.endAddress} ${str}\n订单到付：${detail.paymentArrival} ${detail.isNoticeReleaseCar?.value === 1 ? '等通知放车' : ''}\n温馨提示：承运商只做交车相关的沟通，若客户询问其它，让联系业务咨询。有私自添加客户微信等撬客户行为，一经发现，终止合作！`
          )
          message.success('文本内容已复制')
          break;
        case 3:
          let name = detail.isNoticeSalesman?.value === 1 ? detail.sourceMan : detail?.picker
          let mobile = detail.isNoticeSalesman?.value === 1 ? detail.salesmanMobile : detail?.pickerMobile
          copyText(
            `订单号：${detail.orderId}\n车牌/车架号：${detail.brand + detail.model + ' ' + detail.vinNo}\n收车人姓名：${name}\n收车人电话：${mobile}\n收车人身份证：${detail?.pickerCertificateNumber || ''}\n订单到付：￥${detail?.paymentArrival} ${detail?.isNoticeReleaseCar?.value === 1 ? '等通知放车' : ''}\n订单备注：${detail?.orderRemark}\n小车备注：${detail?.remark}\n送车信息：${detail?.takeType?.label} ${detail.takeOutAddress}\n温馨提示：承运商只做交车相关的沟通，若客户询问其它，让联系业务咨询。有私自添加客户微信等撬客户行为，一经发现，终止合作！`
          )
          message.success('文本内容已复制')
          break;
      }
    }
    // ---------------------------
    // 运输信息-修改承运商
    const carrierEdit = record => {
      state.record = record
      state.carrierChangeShow = true
    }
    // 运输信息-修改承运商接口
    const carrierChange = (e) => {
      if (e) {
        transEdit({
          params: {
            transportTruckId: state.record.transTruckId,
            totvId: state.record.totvId,
            carrierId: e.id,
            carrierName: e.carrierName
          },
          type: {
            value: 1
          },
          reason: e.reason,
          subType: 102
        }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.carrierChangeShow = false
            context.emit('loadData', true)
            operateData()
          }
        })
      }
    }
    // 运输信息-修改金额
    const editMoney = record => {
      state.record = record
      state.editShow = true
    }
    // 确认修改金额
    const confirmEdit = () => {
      if (state.money) {
        state.btnLoading = true
        transEdit({
          params: {
            transportTruckId: state.record.transTruckId,
            totvId: state.record.totvId,
            changeFee: state.money
          },
          type: {
            value: 1
          },
          reason: state.reason,
          subType: 101
        }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.editShow = false
            context.emit('loadData', true)
            operateData()
          }
        }).finally(() => { state.btnLoading = false })
      } else message.error('请填写金额')
    }
    // 运输信息-对账
    const settlementData = (id, status) => {
      if (status && status.value === 1) {
        state.settlementShow = true
        state.settlementDetail = {}
        vehicleSettlement(id)
          .then(res => {
            if (res.code === 10000) {
              state.settlementDetail = res.data ? res.data : {}
            }
          })
      } else {
        message.warning('还未对账')
      }
    }
    // 运输信息-撤销到达
    const repealClick = record => {
      transEdit({
        params: {
          transportTruckId: record.transTruckId,
          totvId: record.totvId
        },
        type: {
          value: 1
        },
        reason: state.repealRemark,
        subType: 104
      }).then(res => {
        if (res.code === 10000) {
          message.success('撤销到达成功')
          context.emit('loadData', true)
          operateData()
        }
      }).finally(() => { state.repealRemark = '' })
    }
    onMounted(() => {



    })

    const closeEditShow = () => {
      state.editShow = false
      state.money = null
      state.reason = ''
    }

    return {
      ...toRefs(state),
      carrierRef,
      imgListRef,
      addReportRef,
      bigCarChooseRef,
      copyData,
      closeChange,
      closeEditShow,
      editMoney,
      repealClick,
      confirmEdit,
      settlementData,
      carrierChange,
      carrierEdit,
      choosePlant,
      getEntrust,
      confirm,
      startCar,
      reportClick,
      updateImg,
      confirmHalfway,
      confirmBigCar,
      endAddressChange,
      cancelLoadVeh,
      chooseDriver,
      confirmArrival,
      confirmCarrier,
      chooseCarrier,
      getInspector,
      operateData,
      getData,
      prompt,
      nullBtn,
      handleCope,
      addReportData,
      confirmInspection,
      inspectorComplete,
      takeCarComplet,
      getLineData,
      chooseBigCarShow,
      arrangeLoadVeh,
      stopAddressChange,
      getPassingPointData,
      getBigCarData,
      confirmTrans,
      changeEndAddress,
      arrangeInfo,
      columnsSend,
      revokeSend,
      detailsShow,
      lookVehicleImg,
      taskOperation,
      lookID,
      vehicleGps,
      lookVehicleGps,
      visibleLookDeliver,
      lookDeliveryCard,
      lookImgID,
      disabledDate,
      lookCarNo
    }
  }
}
</script>

<style lang="less" scoped>
@import '../index.less';

:deep(.ant-table-content) {
  font-size: 12px;
}

:deep(.ant-table-placeholder) {
  display: none !important;
}

:deep(.ant-popover-message-title) {
  padding-left: 0 !important;
}

:deep(.anticon-exclamation-circle) {
  display: none !important;
}

:deep(.ant-input-number) {
  border: none !important;
}

:deep(.ant-input-group-addon) {
  border: none;
  background-color: #fff;
}

:deep(.ant-input-group-wrapper) {
  .ant-input {
    border: none !important;
  }
}

.ant-input-number:outline {
  border: none !important;
}

:deep(.ant-divider) {
  // width: 200%;
  margin: 10px 0;
  // margin-left: -100px;
}

:deep(.ant-input-number-input) {
  border: none;
}

:deep(.ant-btn-dashed) {
  margin-right: 10px;
  border: 1px solid #0066cc;
  color: #fff;
  background-color: #0066cc;
}

.input-div {
  display: flex;
  align-items: center;

  .input-data {
    margin-right: 10px;
    padding-left: 5px;
    display: flex;
    align-items: center;
    border: 1px solid #ccc;

    :deep(.ant-input) {
      border: none !important;
    }
  }
}

.operate-div {
  .operate-title {
    margin: 5px 0 10px 0;
    font-weight: 700;
    font-style: normal;
    color: #344563;
  }

  .operate-content {
    position: relative;
    padding: 10px;
    background-color: #fafafa;
    overflow: hidden;
  }
}

.QRC-img {
  width: 200px;
  border: 1px solid #ccc;
  padding: 5px 20px;
  // right: 0;
  // position: absolute;
  background-color: #fff;
  // z-index: 9;
}

.operation {
  position: relative;
}
</style>
