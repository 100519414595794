<template>
  <div>
    <a-modal :visible="visible" title="我的待接单" centered footer="" @cancel="cancel" width="80%" :bodyStyle="{ padding:'10px',background:'#EEEEEE'}">
      <SearchList :loading="loading" :iptData="iptData" @searchAdd="searchAdd" @resetBtn="resetBtn">
        <template #tail>
          <a-popconfirm title="确认接单吗?" ok-text="确定" cancel-text="取消" @confirm="batchSubmit">
          <a-button :disabled="selectedRowKeys.length === 0" type="primary">批量接单</a-button>
          </a-popconfirm>
        </template>
      </SearchList>
      <div class="m-t1 tableBox">
        <div class="sys-screenTitle">当前筛选出&nbsp;<a>{{ data.length }}</a>&nbsp;条</div>
        <a-table ref="tableRef" :row-selection="{ selectedRowKeys:selectedRowKeys, onChange: onSelectChange ,getCheckboxProps:onGetCheckboxProps}" :dataSource="dataSource" :rowKey="item => item.orderId" :columns="columns" size="small" :pagination="false" :loading="loading" bordered :scroll="{y:500}">
          <template #sort="{ index }">
            {{ index + 1 }}
          </template>

          <template #startAddress="{ record }">
            <div>起:{{ record.startAddress }}</div>
            <div>终:{{ record.endAddress }}</div>
          </template>
          <template #add="{ record }">
            <div v-show="record.dispatchReceiveStatus.value === 0">
              <a-popconfirm title="确认接单吗?" ok-text="确定" cancel-text="取消" @confirm="confirm(record)">
                <a>接单</a>
              </a-popconfirm>
            </div>
            <span style="color:red" v-show="record.dispatchReceiveStatus.value === 1">{{ record.dispatchReceiveStatus.label }}</span>
          </template>
          <template #dispatchRemark="{ record }">
            <div v-show="record.dispatchReceiveStatus.value === 1" style="display:flex" @mousemove="remarkId = record.dispatchId">
              <div class="remark-content">
                <a-tooltip color="#fff">
                  <template #title>
                    <span style="color:#000">{{ record.dispatchRemark }}</span>
                  </template>
                  <span>{{ record.dispatchRemark }}</span>
                </a-tooltip>
                <a v-show="!record.dispatchRemark" @click="openDisRemarks(record)">新增调度备注</a>
              </div>
              <div style="display:flex;align-items:center;min-width:30px" class="m-l1">
                <a v-show="record.dispatchRemark" @click="openDisRemarks(record)">记录</a>
              </div>
            </div>
          </template>
        </a-table>
      </div>
      <!-- 新增/编辑调度备注 -->
      <PendingLogs @setRemaekOk="setRemaekOk" :remarkId="remarkId" :isOverTime="isOverTime" v-if="remarkShow" v-model:visible="remarkShow" :orderVehicleId="logsVehicleId" />
      <!-- 小车详情 -->
      <SmallVehDetails ref="smallVehDetailsRef" @departureSuccessful="departureSuccessful"></SmallVehDetails>
    </a-modal>
  </div>
</template>

  <script setup>
import { onMounted, ref, reactive, nextTick } from 'vue'
import SearchList from '@/components/searchList'
import { transportWaiteReceive, dispatchReceive } from '@/api/newTransport/newTransport'
import { useStore } from 'vuex'
import SmallVehDetails from '@/components/smallVehDetails'
import PendingLogs from './PendingLogs'
import { addressTran, transitionTimeYMD, IfNull } from '@/utils/util'
import { focusCancel } from '@/api/transport/transport'
import { cloneDeep } from 'lodash-es'
import { message } from 'ant-design-vue'
import { ColumnProps } from 'ant-design-vue/es/table/interface';

const store = useStore()
const emit = defineEmits(['update:visible'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  info: {
    type: Object,
    default: {}
  },
  tableIdx: {
    type: Number,
    default: null
  },
  vehicleNumType: {
    type: Number,
    default: 0
  }
})

const cancel = () => {
  emit('update:visible', false)
}

const selectedRowKeys = ref([])
const tableRef = ref(null)
const indexKey = ref(0)
const dataSource = ref([])
const data = ref([])
const loading = ref(false)
const pageSize = ref(50)
const remarkShow = ref(false)
const logsVehicleId = ref('')
const isOverTime = reactive({
  isOverTime: '',
  transportType: '',
  taskType: '',
})
const remarkId = ref('')
const smallVehDetailsRef = ref(null)
const searchMsg = ref({
})


const batchSubmit = () => {
  if (selectedRowKeys.value.length === 0) {
    message.warning('请选择车辆')
    return
  }
  if (selectedRowKeys.value.length === dataSource.value.length) {
    if (selectedRowKeys.value.length < data.value.length) {
      message.warn(`已选择${selectedRowKeys.value.length}条数据，共${data.value.length}条，如需选择更多，请把数据下拉到底加载更多`, 3)
    }
  }
  confirm('',selectedRowKeys.value)
}
const onSelectChange = (Keys, selectedRows) => {
  selectedRowKeys.value = Keys


}
const onGetCheckboxProps = (record) => {
  return {
    disabled: record.dispatchReceiveStatus.value === 1,
  };
}
const confirm = (record,arr) => {
  let ids = []
 if(!arr){
  ids = [ record.orderId]
 }else{
  ids = arr
 }
  dispatchReceive({
    orderIds: ids
  }).then(res => {
    if (res.code !== 10000) return
    message.success('接单成功，请及时填写调度备注')
    selectedRowKeys.value = []
    loading.value = true
    setTimeout(() => {
      reqWaitPlan()
    }, 800)
  })
}


const searchAdd = (e) => {
  let msg = cloneDeep(e)
  msg.startAddress = addressTran(msg.startAddress)
  msg.endAddress = addressTran(msg.endAddress)
  msg.lastTime = transitionTimeYMD(msg.lastTime)
  searchMsg.value = msg
  reqWaitPlan()
}
const resetBtn = () => {
  searchMsg.value = {}
  reqWaitPlan()
}

const openDisRemarks = (record) => {
  if(record.dispatchReceiveStatus.value === 0){
    message.error('还未接单不能填写调度备注')
    return
  }
  isOverTime.isOverTime = record.isOverTime?.value
  isOverTime.transportType = record.transportType?.value
  isOverTime.taskType = record.taskType
  remarkShow.value = true
  logsVehicleId.value = record.orderId
}
const departureSuccessful = () => {
  reqWaitPlan()
}


const reqWaitPlan = () => {
  loading.value = true
  transportWaiteReceive({
    ...searchMsg.value,
  }).then(res => {
    if (res.code !== 10000) return
    data.value = res.data ? res.data : []
    dataSource.value = data.value.slice(0, pageSize.value)
  }).finally(() => {
    loading.value = false
  })
}

const setRemaekOk = (id) => {
  remarkShow.value = false
  dataSource.value.forEach((item,index) => {
     if(item.orderId === id){
      dataSource.value.splice(index, 1)
     }
  });
}
onMounted(() => {
  reqWaitPlan()
  nextTick(() => {
    const tableBody = tableRef.value.$el.querySelector('.ant-table-body');
    tableBody.addEventListener('scroll', (event) => {
      if (dataSource.value.length === 0) return
      if (pageSize.value > data.value.length) return
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      if (scrollTop + clientHeight >= scrollHeight - 100) {
        // 滚动条触底，执行加载数据的操作
        pageSize.value += 50
        dataSource.value = data.value.slice(0, pageSize.value)
        // 这里可以调用你的加载数据函数
      }
    });
  })
})

const iptData = ref([

  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "orderId",
    width: 200,
  },
  {
    type: "input",
    placeholder: "录单员",
    value: null,
    prop: "salesman",
    width: 200,
  },
  {
    type: "input",
    placeholder: "调度员",
    value: null,
    prop: "dispatcher",
    width: 200,
  },
  {
    type: "select",
    placeholder: "接单状态",
    value: null,
    prop: "dispatchReceiveStatus",
    opt:[
      {
        name:'未接单',
        id:0
      },
      {
        name:'接单未备注',
        id:1
      },
    ],
    width: 200,
  },
])
const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    slots: {
      customRender: 'sort'
    },
    align: 'center',
    width: 50,
  },


  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center',
  },
  {
    title: '车辆数量',
    dataIndex: 'vehicleNum',
    align: 'center'
  },
  {
    title: '调度备注',
    dataIndex: 'dispatchRemark',
    slots: {
      customRender: 'dispatchRemark'
    },
    align: 'center',
    width: 150
  },
  {
    title: '录单员',
    dataIndex: 'salesman',
    align: 'center',
  },
  {
    title: '调度员',
    dataIndex: 'dispatcher',
    align: 'center',
  },
  {
    title: '确认运输时间',
    dataIndex: 'confirmTime',
    align: 'center',
  },
  {
    title: '起-终',
    dataIndex: 'startAddress',
    align: 'center',
    slots: {
      customRender: 'startAddress'
    },
  },
  {
    title: '操作',
    dataIndex: 'add',
    slots: {
      customRender: 'add'
    },
    align: 'center',
    width: 100
  },

])
  </script>

  <style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}

.statusBox {
  border-bottom: 1px solid #eee;
  background: #fff;
}

.item {
  padding: 10px;
  border-bottom: 4px solid transparent;
  margin-right: 40px;
  font-size: 16px;
}
.changeItem {
  border-bottom: 4px solid #0066cc;
  color: #0066cc;
}
.remark-content {
  width: 80%;
  max-height: 80px;
  overflow: hidden;
  text-align: left;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.isExpatriate {
  min-width: 16px;
  height: 16px;
  background-color: rgba(57, 106, 252, 1);
  border-radius: 2px;
  font-size: 10px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}
.ant-typography {
  margin: 0;
}
</style>
