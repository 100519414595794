<template>
  <div>
    <div ref="addCloudModal">
      <a-modal width="40%" :centered="true" :visible="visible" title="调度日志" :footer="null" @cancel="cancel" :getContainer="() => $refs.addCloudModal">
        <div class="flex ju-between">
          <div class="logsList m-l1">
            <div class="overBox">
              <div v-if="loading" class="m-t4 flex al-center ju-center">
                <a-spin />
              </div>
              <div v-else>
                <div v-if="dataList.length == 0" class="m-t4">
                  <a-empty />
                </div>
                <div v-else>
                  <div v-for="item in dataList" :key="item.id" class="m-t2 itemBox pos-rel">
                    <div style="width: 90%;">
                      <div v-if="item.carrierName">承运商：<span>{{ item.carrierName }}</span></div>
                      <div v-if="item.cost">本段价格：<span>{{ item.cost }}元</span></div>
                      <div v-if="item.lineName">本段线路：<span>{{ item.lineName }}</span></div>
                      <div v-if="item.arrangeRemark">工作交接留言：<span>{{ item.arrangeRemark }}</span></div>
                      <div v-if="item.labelName">自定义标签：<span>{{ item.labelName }}</span></div>
                      <div v-if="item.arrangeLoadTime">计划发车时间：<span>{{ item.arrangeLoadTime }}</span></div>
                      <div v-if="item.exceptionType?.label">异常类型：<span>{{ item.exceptionType?.label }}</span></div>
                      <div v-if="item.exceptionSubType?.label">异常原因：<span>{{ item.exceptionSubType?.label }}</span></div>
                      <div v-if="item.exceptionExplain">异常情况说明：<span>{{ item.exceptionExplain }}</span></div>
                      <div style="margin:15px 0;color:#B5BBC5;width:100%" class="flex ju-between">
                        <div> {{ item.arranger }} </div>{{ item.createTime }}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="m-t2 m-b1 flex ju-center">
              <a-pagination v-model:current="pagination.current" @change="changePage" size="small" :page-size="pagination.size" :total="pagination.total" :show-total="pagination.showTotal" />
            </div>
          </div>

        </div>
      </a-modal>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue'
import { arrangePage } from '@/api/transport/transport'

const props = defineProps({
  orderVehicleId: {
    type: Number,
    default: ''
  },
  visible: {
    type: Boolean,
    default: false
  },
  remarkId: {
    type: String,
    default: ''
  },
  isOverTime: {
    type: Object,
    default: {}
  }
})
const emit = defineEmits(['update:visible'])

const pagination = ref({
  total: '',
  showTotal: (total) => `共${total}条`,
  current: 1,
  size: 4
})

const loading = ref(false)
const dataList = ref([])






const changePage = (e) => {
  pagination.value.current = e
  reqArrangePage()
}
const reqArrangePage = () => {
  loading.value = true
  arrangePage({
    orderVehicleId: props.orderVehicleId,
    current: pagination.value.current,
    size: pagination.value.size
  }).then((res) => {
    if (res.code !== 10000) return
    dataList.value = res.data.records
    pagination.value.total = res.data.total
  }).finally(() => {
    loading.value = false
  })
}
const cancel = () => {
  emit('update:visible', false)
}
onMounted(() => {
  reqArrangePage()
})

</script>

<style lang="less" scoped>
:deep(.ant-modal-body) {
  padding: 0 !important;
  background: #f4f5f7 !important;
}
:deep(.ant-btn-primary) {
  border-color: #1890ff !important;
  background-color: #1890ff !important;
}
.logsList {
  width: 100%;
  padding: 10px 0 10px 25px;
  background: #fff;
  .overBox {
    padding-right: 20px;
    height: 660px;
    overflow: auto;
  }
  .itemBox {
    border-bottom: 1px solid #eeeeee;
    color: #6b778c;
    span {
      color: #344563;
    }
  }
}
.overBox::-webkit-scrollbar {
  width: 5px;
}
/* 滚动条的轨道 */
.overBox::-webkit-scrollbar-track {
  /* 设置滚动条轨道的背景色 */
  background-color: #fff;
  cursor: pointer;
}
/* 滚动条的滑块 */
.overBox::-webkit-scrollbar-thumb {
  /* 设置滚动条滑块的背景色 */
  background-color: #daebfa;
  width: 5px;
  border-radius: 8px;
}
.formBox {
  width: 49%;
  padding: 10px 20px;
  background: #fff;
  .title {
    color: #344563;
    font-size: 16px;
    font-weight: 600;
  }
  .reqText {
    font-family: SimSun, sans-serif;
    color: red;
  }
}
</style>