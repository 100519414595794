<template>
  <div>
    <a-modal :visible="visible" title="我的关注" centered footer="" @cancel="cancel" width="80%" :bodyStyle="{ padding:'10px',background:'#EEEEEE'}">
      <div class="statusBox flex">
        <div v-for="item in tabstatus" :key="item.id" class="item cur-p" @click="changeBtn(item)" :class="indexKey === item.colorType ?'changeItem':''">
          {{ item.name }}({{ item.total }})
        </div>
      </div>
      <SearchList :loading="loading" :iptData="iptData" @searchAdd="searchAdd" @resetBtn="resetBtn"></SearchList>
      <div class="m-t1 tableBox">
        <div class="sys-screenTitle">当前筛选出&nbsp;<a >{{ data.length }}</a>&nbsp;条</div>
        <a-table ref="tableRef" :dataSource="dataSource" :rowKey="item => item.orderVehicleId" :columns="columns" size="small" :pagination="false" :loading="loading" bordered :scroll="{y:500}">
          <template #sort="{ index }">
            {{ index + 1 }}
          </template>
          <template #vinNo="{ record }">
            <div class="flex al-center ju-center">
              <a-popover>
                <template #content>
                  {{ record.taskCarrierName }}
                </template>
                <div class="isExpatriate cur-p" v-if="record.taskId">
                  派
                </div>
              </a-popover>
              <div style="word-break:break-all"> <a @click="model(record)">{{ record.vinNo }}</a></div>
              <a-typography-paragraph :copyable="{ text: record.vinNo }"></a-typography-paragraph>
            </div>
            <div><a @click="model(record)">{{ record.brand }}{{ record.model }}</a></div>
          </template>
          <template #transportType="{ record }">
           {{ record.transportType.label }} - {{ record.transStatus.label }}
          </template>
          <template #startAddress="{ record }">
            <div>起:{{ record.startAddress }}</div>
            <div>终:{{ record.endAddress }}</div>
          </template>
          <template #lastOperator="{ record }">
            <div>{{ record.lastOperator }}</div>
            <div>{{ record.lastAddress }}</div>
          </template>
          <template #add="{ record }">
            <a @click="cancelFocus(record)">取消关注</a>
          </template>
          <template #dispatchRemark="{ record }">
            <div style="display:flex" @mousemove="remarkId = record.dispatchId">
              <div class="remark-content">
                <a-tooltip color="#fff">
                  <template #title>
                    <span style="color:#000">{{ record.dispatchRemark }}</span>
                  </template>
                  <span>{{ record.dispatchRemark }}</span>
                </a-tooltip>
                <a v-show="!record.dispatchRemark" @click="openDisRemarks(record)">新增调度备注</a>
              </div>
              <div style="display:flex;align-items:center;min-width:30px" class="m-l1">
                <a v-show="record.dispatchRemark" @click="openDisRemarks(record)">记录</a>
              </div>
            </div>
          </template>
        </a-table>
      </div>
      <!-- 新增/编辑调度备注 -->
      <SchedulingLogs @setRemaekOk="setRemaekOk" :remarkId="remarkId" :isOverTime="isOverTime" v-if="remarkShow" v-model:visible="remarkShow" :orderVehicleId="logsVehicleId" />
      <!-- 小车详情 -->
      <SmallVehDetails ref="smallVehDetailsRef" @focusCancel="focusCancelAdd" @departureSuccessful="departureSuccessful"></SmallVehDetails>
    </a-modal>
  </div>
</template>
  
  <script setup>
import { onMounted, ref, reactive, nextTick } from 'vue'
import SearchList from '@/components/searchList'
import {  focusList as timeoutVehicleAnl   } from '@/api/newTransport/newTransport'
import { useStore } from 'vuex'
import SmallVehDetails from '@/components/smallVehDetails'
import SchedulingLogs from '@/views/components/transport/smallVehicleOperate/schedulingLogs.vue'
import { addressTran, transitionTimeYMD, IfNull } from '@/utils/util'
import { focusCancel } from '@/api/transport/transport'
import { cloneDeep } from 'lodash-es'
import { message } from 'ant-design-vue'

const store = useStore()
const emit = defineEmits(['update:visible'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  info: {
    type: Object,
    default: {}
  },
  tableIdx: {
    type: Number,
    default: null
  },
  vehicleNumType: {
    type: Number,
    default: 0
  }
})
const cancel = () => {
  emit('update:visible', false)
}

const tableRef = ref(null)
const indexKey = ref(0)
const dataSource = ref([])
const data = ref([])
const loading = ref(false)
const pageSize = ref(50)
const remarkShow = ref(false)
const logsVehicleId = ref('')
const isOverTime = reactive({
  isOverTime: '',
  transportType: '',
  taskType: '',
})
const remarkId = ref('')
const smallVehDetailsRef = ref(null)
const searchMsg = ref({
})
//取消关注
const cancelFocus = (record) => {
  let params = {
    orderVehicleId: record.orderVehicleId
  }
  focusCancel(params).then(res => {
    if (res.code !== 10000) return
    dataSource.value.forEach((item,index) => {
      if (item.orderVehicleId === record.orderVehicleId) {
        dataSource.value.splice(index, 1)
      }
    })
    message.success('取消成功')
  })
}
const focusCancelAdd = (id) =>{
  dataSource.value.forEach((item,index) => {
      if (item.orderVehicleId === id) {
        dataSource.value.splice(index, 1)
      }
    })
}
const searchAdd = (e) => {
  let msg = cloneDeep(e)
  msg.startAddress = addressTran(msg.startAddress)
  msg.endAddress = addressTran(msg.endAddress)
  msg.lastTime = transitionTimeYMD(msg.lastTime)
  searchMsg.value = msg
  reqWaitPlan()
}
const resetBtn = () => {
  searchMsg.value = {  }
  reqWaitPlan()
}
const model = e => {
  smallVehDetailsRef.value.detail = e
  smallVehDetailsRef.value.windowShow = true
  setTimeout(() => {
    smallVehDetailsRef.value.onOk()
  }, 100)
}
const openDisRemarks = (record) => {
  isOverTime.isOverTime = record.isOverTime?.value
  isOverTime.transportType = record.transportType?.value
  isOverTime.taskType = record.taskType
  remarkShow.value = true
  logsVehicleId.value = record.orderVehicleId
}
const departureSuccessful = () => {
  reqWaitPlan()
}


const reqWaitPlan = () => {
  loading.value = true
  timeoutVehicleAnl({
    ...searchMsg.value,
  }).then(res => {
    if (res.code !== 10000) return
    data.value = res.data ? res.data : []
    dataSource.value = data.value.slice(0, pageSize.value)
  }).finally(() => {
    loading.value = false
  })
}

const setRemaekOk = (str, label, playTime) => {
  if (IfNull(str)) {
    data.value.forEach(item => {
      if (item.dispatchId == remarkId.value) {
        item.dispatchRemark = str;
      }
    })
  }
  if (label) {
    data.value.forEach(item => {
      if (item.dispatchId == remarkId.value) {
        item.labelName = label;
      }
    })
  }
}
onMounted(() => {

  reqWaitPlan()
  nextTick(() => {
    const tableBody = tableRef.value.$el.querySelector('.ant-table-body');
    tableBody.addEventListener('scroll', (event) => {
      if (dataSource.value.length === 0) return
      if (pageSize.value > data.value.length) return
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      if (scrollTop + clientHeight >= scrollHeight - 100) {
        // 滚动条触底，执行加载数据的操作
        pageSize.value += 50
        dataSource.value = data.value.slice(0, pageSize.value)
        // 这里可以调用你的加载数据函数
      }
    });
  })
})

const iptData = ref([
  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "startAddress",
    width: 200,
    changeOn: true
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    prop: "endAddress",
    width: 200,
    changeOn: true
  },
  {
    type: "select",
    placeholder: "是否外派",
    value: null,
    prop: "isTask",
    width: 200,
    opt: [
      {
        name: '是',
        id: 1
      },
      {
        name: '否',
        id: 0
      }
    ]
  },
  {
    type: "select",
    placeholder: "标签类型",
    value: null,
    prop: "labelId",
    width: 200,
    opt: store.state.app.labelList
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "orderId",
    width: 200,
  },
  {
    type: "input",
    placeholder: "录单员",
    value: null,
    prop: "salesman",
    width: 200,
  },
  {
    type: "input",
    placeholder: "调度备注",
    value: null,
    prop: "dispatchRemark",
    width: 200,
  },
  {
    type: "input",
    placeholder: "车牌号",
    value: null,
    prop: "vinNo",
    width: 200,
  },
])
const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    slots: {
      customRender: 'sort'
    },
    align: 'center',
    width: 50,
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    slots: {
      customRender: 'vinNo'
    },
    align: 'center',
  },
  {
    title: '运输状态',
    dataIndex: 'transportType',
    slots: {
      customRender: 'transportType'
    },
    align: 'center',
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center',
  },
  {
    title: '调度备注',
    dataIndex: 'dispatchRemark',
    slots: {
      customRender: 'dispatchRemark'
    },
    align: 'center',
    width: 150
  },
  {
    title: '录单员',
    dataIndex: 'salesman',
    align: 'center',
  },

  {
    title: '确认运输时间',
    dataIndex: 'confirmTime',
    align: 'center',
  },
  {
    title: '起-终',
    dataIndex: 'startAddress',
    align: 'center',
    slots: {
      customRender: 'startAddress'
    },
  },
  {
    title: '标签',
    dataIndex: 'labelName',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'add',
    slots: {
      customRender: 'add'
    },
    align: 'center',
    width: 100
  },

])
  </script>
  
  <style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}

.statusBox {
  border-bottom: 1px solid #eee;
  background: #fff;
}

.item {
  padding: 10px;
  border-bottom: 4px solid transparent;
  margin-right: 40px;
  font-size: 16px;
}
.changeItem {
  border-bottom: 4px solid #0066cc;
  color: #0066cc;
}
.remark-content {
  width: 80%;
  max-height: 80px;
  overflow: hidden;
  text-align: left;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.isExpatriate {
  min-width: 16px;
  height: 16px;
  background-color: rgba(57, 106, 252, 1);
  border-radius: 2px;
  font-size: 10px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}
.ant-typography {
  margin: 0;
}
</style>