export default {
  // 存储数据（单位：秒）
  set(key, value='', expireSeconds = 3600) {
    const data = {
      value:value,
      expire: Date.now() + expireSeconds * 1000
    };
    localStorage.setItem(key, JSON.stringify(data));
  },

  // 获取数据（自动清理过期项）
  get(key) {
    const dataStr = localStorage.getItem(key);
    if (!dataStr) return null;

    const data = JSON.parse(dataStr);
    if (Date.now() > data.expire) {
      localStorage.removeItem(key);
      return null;
    }
    return data.value;
  },
}