<template>
  <div>
    <a-modal :visible="visible" title="自定义标签" footer="" centered @cancel="cancel" width="80%" :bodyStyle="{ padding:'10px',background:'#EEEEEE'}">
      <div class="statusBox flex">
        <div v-for="item in tabstatus" :key="item.id" class="item cur-p" @click="changeBtn(item)" :class="indexKey === item.objectId ?'changeItem':''">
          {{ item.title }} {{ item.num }}
        </div>
      </div>
      <SearchList :iptData="iptData" :loading="loading" @searchAdd="searchAdd" @resetBtn="resetBtn"></SearchList>
      <div class="m-t1 tableBox">
        <div class="sys-screenTitle">当前筛选出&nbsp;<a >{{ data.length }}</a>&nbsp;条</div>
        <a-table ref="tableRef" :dataSource="dataSource" :rowKey="item => item.orderVehicleId" size="small" :columns="columns" bordered :pagination="false" :scroll="{y:500,x:1800}" :loading="loading">
          <template #sort="{ index }">
            {{ index+1 }}
          </template>
          <template #add="{ record }">
            <a @click="model(record)">操作</a>
          </template>
          <template #vinNo="{ record }">
            <div class="flex al-center ju-center">
              <a-popover>
                <template #content>
                  {{ record.taskCarrierName }}
                </template>
                <div class="isExpatriate cur-p" v-if="record.taskId">
                  派
                </div>
              </a-popover>
              <div style="word-break:break-all"> {{ record.vinNo }}</div>
              <a-typography-paragraph :copyable="{ text: record.vinNo }">
              </a-typography-paragraph>
            </div>
            <div>{{ record.brand }}{{ record.model }}</div>
          </template>
          <template #startAddress="{ record }">
            {{ record.startAddress }}
           <div> {{ record.endAddress }}</div>
          </template>
          <template #deliveryCard="{ record }">
            <div class="flex" v-if="record.pic?.value === 1">
              <div class="picInfoBox">照片：</div>
              <a @click="lookVehicleImgs(record)">查看</a>
            </div>
            <div style="text-align: left;" v-if="record.address?.value === 1">
              地址：<a @click="lookVehicleGps(record)">查看</a>
              ({{ record.gpsCity}}{{ record.gpsTime}})
            </div>
            <div class="flex" v-if="record.deliveryCard?.value === 1">
              <div class="picInfoBox">交车条：</div>
              <a @click="lookDeliveryCard(record)">查看</a>
            </div>
            <div v-if="record.taskStartTime" style="text-align: left;">
              发运时间：{{ record.taskStartTime}}
            </div>
          </template>
          <template #dispatchRemark="{ record }">
            <div style="display:flex" @mousemove="remarkId = record.dispatchId">
              <div class="remark-content">
                <a-tooltip color="#fff">
                  <template #title>
                    <span style="color:#000">{{ record.dispatchRemark }}</span>
                  </template>
                  <span>{{ record.dispatchRemark }}</span>
                </a-tooltip>
                <a v-show="!record.dispatchRemark" @click="openDisRemarks(record)">新增调度备注</a>
              </div>
              <div style="display:flex;align-items:center;min-width:30px" class="m-l1">
                <a v-show="record.dispatchRemark" @click="openDisRemarks(record)">记录</a>
              </div>
            </div>
          </template>
        </a-table>
      </div>
        <!-- 小车详情 -->
        <SmallVehDetails ref="smallVehDetailsRef"></SmallVehDetails>
        <!-- 新增/编辑调度备注 -->
      <SchedulingLogs @setRemaekOk="setRemaekOk" :remarkId="remarkId" :isOverTime="isOverTime" v-if="remarkShow" v-model:visible="remarkShow" :orderVehicleId="logsVehicleId" />
           <!-- 车辆照片 -->
           <VehicleImg ref="vehicleImgRefs" />
      <!-- 在途位置 -->
      <VehicleGps ref="vehicleGpss" />
      <!-- 交车条 -->
      <PictureViewer v-model:visible="visibleLookDeliver" :lookImgID="lookImgID"></PictureViewer>
    </a-modal>
  </div>
</template>
  
  <script setup>
import { onMounted, ref, reactive, nextTick } from 'vue'
import { addressTran, IfNull } from '@/utils/util'
import enumAll from '@/store/modules/enumAll'
import SearchList from '@/components/searchList'
import { customLabel } from '@/api/newTransport/newTransport'
import SchedulingLogs from '@/views/components/transport/smallVehicleOperate/schedulingLogs.vue'
import SmallVehDetails from '@/components/smallVehDetails'
import { cloneDeep } from 'lodash-es'
import VehicleImg from '@/views/components/vehicleImg'
import VehicleGps from '@/views/order/positionInfo/carGps.vue'
import PictureViewer from '@/components/PictureViewer'
import { taskDeliveryPic } from '@/api/transport/transport'

const emit = defineEmits(['update:visible'])


const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  info: {
    type: Object,
    default: {}
  },
  subItemList: {
    type: Array,
    default: []
  },

})
const cancel = () => {
  emit('update:visible', false)
}

const tableRef = ref(null)
const lookImgID = ref([])
const visibleLookDeliver = ref(null)
const vehicleGpss = ref(null)
const vehicleImgRefs = ref(null)
const indexKey = ref(null)
const loading = ref(false)
const dataSource = ref([])
const data = ref([])
const smallVehDetailsRef = ref(null)
const searchMsg = ref({

})
const remarkId = ref('')
const isOverTime = reactive({
  isOverTime: '',
  transportType: '',
  taskType: '',
})
const logsVehicleId = ref('')
const remarkShow = ref(false)
const pageSize = ref(50)


// 查看交车条
const lookDeliveryCard = (record) => {
  taskDeliveryPic(record.orderVehicleId, {
    taskId: record.taskId
  }).then(res => {
    if (res.code !== 10000) return
    lookImgID.value = res.data.map(item => item.fileId)
    visibleLookDeliver.value = true
  })
}
// 查看视频图片
const lookVehicleImgs = (record) => {
  setTimeout(() => {
    vehicleImgRefs.value.vehicleImg(record.orderVehicleId, record.vinNo)
  }, 50)
}
// 查看位置
const lookVehicleGps = (record) => {
  setTimeout(() => {
    vehicleGpss.value.onvisible(true)
    vehicleGpss.value.onorderVehicleId(record.orderVehicleId)
  }, 50)
}
const changeBtn = (e) => {
  indexKey.value = e.objectId
  reqCustomLabel()
}
const openDisRemarks = (record) => {
  isOverTime.isOverTime = record.isOverTime.value
  isOverTime.transportType = record.transportType.value
  isOverTime.taskType = record.taskType
  remarkShow.value = true
  logsVehicleId.value = record.orderVehicleId
}
const searchAdd = (e) => {
  let msg = cloneDeep(e)
  msg.startAddress = addressTran(msg.startAddress)
  msg.endAddress = addressTran(msg.endAddress)
  searchMsg.value = msg
  reqCustomLabel()
}
const resetBtn = () => {

  reqCustomLabel()
}
const model = e => {
  smallVehDetailsRef.value.detail = e
  smallVehDetailsRef.value.windowShow = true
  setTimeout(() => {
    smallVehDetailsRef.value.onOk()
  }, 100)
}
const reqCustomLabel = () => {
  ResetScroll()
  loading.value = true
  customLabel({
    labelId:  indexKey.value,
    ...searchMsg.value
  }).then(res => {
    if (res.code !== 10000) return
    data.value = res.data ? res.data : []
    dataSource.value = data.value.slice(0, pageSize.value)
  }).finally(() => {
    loading.value = false
  })
}
const ResetScroll = () => {
  pageSize.value = 50
  dataSource.value = []
}
const setRemaekOk = (str, label) => {
  if (IfNull(str)) {
    data.value.forEach(item => {
      if (item.dispatchId == remarkId.value) {
        item.dispatchRemark = str;
      }
    })
  }
  if(label){
    data.value.forEach(item => {
      if (item.dispatchId == remarkId.value) {
        item.labelName = label;
      }
    })
  }
}
onMounted(() => {

  tabstatus.value = props.subItemList
  indexKey.value = props.info.objectId
  reqCustomLabel()
  nextTick(() => {
    const tableBody = tableRef.value.$el.querySelector('.ant-table-body');
    tableBody.addEventListener('scroll', (event) => {
      if (dataSource.value.length === 0) return
      if (pageSize.value > data.value.length) return
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      if (scrollTop + clientHeight >= scrollHeight - 100) {
        // 滚动条触底，执行加载数据的操作
        pageSize.value += 50
        dataSource.value = data.value.slice(0, pageSize.value)
        // 这里可以调用你的加载数据函数
      }
    });
  })
})
const tabstatus = ref([

])
const iptData = ref([
  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "startAddress",
    width: 200,
    changeOn: true
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    prop: "endAddress",
    width: 200,
    changeOn: true
  },
  {
    type: "select",
    placeholder: "是否外派",
    value: null,
    prop: "isTask",
    width: 200,
    opt: [
      {
        name: '是',
        id: 1
      },
      {
        name: '否',
        id: 0
      }
    ]
  },
  {
    type: "select",
    placeholder: "是否有预发车时间",
    value: null,
    prop: "isPreDepartureTime",
    width: 200,
    opt: [
      {
        name: '是',
        id: 1
      },
      {
        name: '否',
        id: 0
      }
    ]
  },
  {
    type: "select",
    placeholder: "车辆状态",
    value: null,
    prop: "transStatus",
    width: 200,
    key: 'value',
    keyVal: 'label',
    opt:enumAll.state.vehicleStatus
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "orderId",
    width: 200,
  },
  {
    type: "input",
    placeholder: "录单员",
    value: null,
    prop: "salesman",
    width: 200,
  },
  {
    type: "input",
    placeholder: "承运商",
    value: null,
    prop: "departureCarrierName",
    width: 200,
  },
  {
    type: "input",
    placeholder: "调度备注",
    value: null,
    prop: "dispatchRemark",
    width: 200,
  },
  {
    type: "input",
    placeholder: "车牌号",
    value: null,
    prop: "vinNo",
    width: 200,
  },
  {
    type: "select",
    placeholder: "台量类型",
    value: null,
    prop: "vehicleNum",
    width: 200,
    opt:[
      {
      id: 1,
      name: '散车'
      },
      {
      id: 2,
      name: '批量车'
      },
    ]
  },
])
const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    align: 'center',
    width: 50,
    slots: {
      customRender: 'sort'
    },
    fixed: 'left'
  },
  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    slots: {
      customRender: 'add'
    },
    width: 70,
    fixed: 'left'
  },
  {
    title: '车牌号/车架-车型',
    dataIndex: 'vinNo',
    slots: {
      customRender: 'vinNo'
    },
    align: 'center',
    fixed: 'left'
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center',
    width:180
  },
  {
    title: '调度备注',
    dataIndex: 'dispatchRemark',
    align: 'center',
    slots: {
      customRender: 'dispatchRemark'
    },
    width: 150
  },
  {
    title: '订单状态',
    dataIndex: 'orderStatus.label',
    align: 'center',
    width: 80
  },
 
  {
    title: '录单员',
    dataIndex: 'salesman',
    align: 'center',
    width:80
  },
  {
    title: '起-终',
    dataIndex: 'startAddress',
    slots: {
      customRender: 'startAddress'
    },
    align: 'center',
    width:200
  },
  {
    title: '当前城市',
    dataIndex: 'nowCity',
    align: 'center',
  },
  {
    title: '预计发车时间',
    dataIndex: 'preDepartureTime',
    align: 'center',
    width: 100
  },
  {
    title: '任务信息',
    dataIndex: 'deliveryCard',
    align: 'center',
    slots: {
      customRender: 'deliveryCard'
    },
    width: 150
  },
  {
    title: '承运商',
    dataIndex: 'departureCarrierName',
    align: 'center',
  },
  {
    title: '标签',
    dataIndex: 'labelName',
    align: 'center',
    width: 100
  },
  {
    title: '超时信息',
    dataIndex: 'overTimeTip',
    align: 'center',
    width:100
  },
  {
    title: '台量类型',
    dataIndex: 'vehicleNum.label',
    align: 'center',
    width: 80
  }
])
  </script>
  
  <style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}

.statusBox {
  width: 100%;
  overflow-x: auto;
  border-bottom: 1px solid #eee;
  background: #fff;
}
.statusBox::-webkit-scrollbar {
  height: 6px;
  cursor: pointer;
}
/* 滚动条的轨道 */
.statusBox::-webkit-scrollbar-track {
  /* 设置滚动条轨道的背景色 */
  background-color: #fff;
  cursor: pointer;
}
/* 滚动条的滑块 */
.statusBox::-webkit-scrollbar-thumb {
  /* 设置滚动条滑块的背景色 */
  background-color: rgb(193,193,193);
  width: 50px;
  border-radius: 8px;
  cursor: pointer;
}

.item {
  padding: 10px;
  border-bottom: 4px solid transparent;
  margin-right: 20px;
  font-size: 16px;
  white-space: nowrap;
}
.changeItem {
  border-bottom: 4px solid #0066cc;
  color: #0066cc;
  
}

.remark-content {
  width: 80%;
  max-height: 80px;
  overflow: hidden;
  text-align: left;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.isExpatriate {
  min-width: 16px;
  height: 16px;
  background-color: rgba(57, 106, 252, 1);
  border-radius: 2px;
  font-size: 10px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}
.ant-typography {
  margin: 0;
}
</style>