<template>
<div>

</div>
</template>

<script setup>
import { ref ,onMounted} from 'vue'
import {  cozeChat, cozeSignJwt, cozeBotId } from '@/api/repository/repository'
import axios from 'axios'
import storage from '@/utils/storage';
import { useStore } from 'vuex'
const url = ref('https://huayue-it-oss.oss-cn-hangzhou.aliyuncs.com/applet/pc/WechatIMG179.jpg')
const store = useStore()

const getCode = () => {
  const myPromise = new Promise((resolve, reject) => {
    cozeSignJwt().then(res => {
      axios({
        method: 'post',
        url: 'https://api.coze.cn/api/permission/oauth2/token',
        data: {
          duration_seconds: 86399,
          grant_type: "urn:ietf:params:oauth:grant-type:jwt-bearer",
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + res
        }
      }).then(res => {
        storage.set('access_token_coze', res.access_token, 86390);
        resolve(res.access_token)
      })
    })
  });
  return myPromise
}
const getBotId = () => {
  cozeBotId().then(res => {
    const cozeWebSDK = new CozeWebSDK.WebChatClient({

      config: {
        // Agent ID

        botId: res.data
      },
      auth: {
        // Authentication methods, the default type is 'unauth', which means no authentication is required; it is recommended to set it to 'token', indicating authentication through PAT (Personal Access Token) or OAuth
        type: 'token',
        // When the type is set to 'token', it is necessary to configure a PAT (Personal Access Token) or OAuth access token for authentication.
        token: storage.get('access_token_coze'),
        // When the access token expires, use a new token and set it as needed.
        onRefreshToken: async () => {
          return await getCode()
        },
      },
      userInfo: {
        id: String(store.getters.info.id),
        url: 'https://huayue-it-oss.oss-cn-hangzhou.aliyuncs.com/applet/pc/20250303140509.png',
        nickname: store.getters.info.name,
      },
      ui: {
        /**
        * The ui.base parameter is used to add the overall UI effect of the chat window.
        * @param base.icon - Application icon URL.
        * @param base.layout - Layout style of the agent chat box window, which can be set as 'pc' or'mobile'.
        * @param base.lang - System language of the agent, which can be set as 'en' or 'zh-CN'.
        * @param base.zIndex - The z-index of the chat box.
        */
        base: {
          icon: url.value,
          layout: 'pc',
          lang: 'en',
          zIndex: 100,
        },
        /**
        * Control the UI and basic capabilities of the chat box.
        * @param chatBot.title - The title of the chatbox
        * @param chatBot.uploadable - Whether file uploading is supported.
        * @param chatBot.width - The width of the agent window on PC is measured in px, default is 460.
        * @param chatBot.el - Container for setting the placement of the chat box (Element).
        */
        chatBot: {
          title: '知识库',
          uploadable: true,
          width: 600,
          onShow: () => {

          },
          onBeforeShow: () => {
            if (storage.get('access_token_coze')) {
            } else {
              getCode()
            }
          }
        },
        /**
        * Controls whether to display the floating ball at the bottom right corner of the page.
        */
        asstBtn: {
          isNeed: true,
        },
        /**
        * The ui.footer parameter is used to add the footer of the chat window.
        * @param footer.isShow - Whether to display the bottom copy module.
        * @param footer.expressionText - The text information displayed at the bottom.
        * @param footer.linkvars - The link copy and link address in the footer.
        */
        footer: {
          isShow: false,
        }
      },
    });
  })
}
onMounted(() => {
  if (!storage.get('access_token_coze')) {
    getCode()
  }
  getBotId()
})
</script>

<style lang="less" scoped>

</style>