import { ref } from 'vue'


// 提123 干456 送789
export const carryiptData1 = ref([
  {
    type: "address",
    placeholder: "取车城市",
    value: null,
    prop: "placeInAddress",
    width: 200,
    changeOn: true
  },
  {
    type: "select",
    placeholder: "是否外派",
    value: null,
    prop: "isTask",
    width: 200,
    opt: [
      {
        name: '是',
        id: 1
      },
      {
        name: '否',
        id: 0
      }
    ]
  },
  {
    type: "select",
    placeholder: "标签类型",
    value: null,
    prop: "labelId",
    width: 200,
    opt: []
  },
  {
    type: "select",
    placeholder: "取车类型",
    value: null,
    prop: "pickType",
    width: 200,
    opt: [
      {
        name: '小板',
        id: 2
      },
      {
        name: '地跑',
        id: 3
      }
    ]
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "orderId",
    width: 200,
  },
  {
    type: "input",
    placeholder: "录单员",
    value: null,
    prop: "salesman",
    width: 200,
  },
  {
    type: "input",
    placeholder: "调度员",
    value: null,
    prop: "dispatcher",
    width: 200,
  },
  {
    type: "input",
    placeholder: "调度备注",
    value: null,
    prop: "dispatchRemark",
    width: 200,
  },
  {
    type: "input",
    placeholder: "车牌号",
    value: null,
    prop: "vinNo",
    width: 200,
  },
  {
    type: "select",
    placeholder: "台量类型",
    value: 1,
    prop: "vehicleNum",
    width: 200,
    opt:[
      {
      id: 1,
      name: '散车'
      },
      {
      id: 2,
      name: '批量车'
      },
    ]
  },
])
export const carrycolumns1 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    width: 50,
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    fixed: 'left'
  },
  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    width: 70,
    slots: {
      customRender: 'add'
    },
    fixed: 'left'
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    slots: {
      customRender: 'vinNo'
    },
    align: 'center',
    fixed: 'left'
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center',
  },
  {
    title: '调度备注',
    dataIndex: 'dispatchRemark',
    align: 'center',
    slots: {
      customRender: 'dispatchRemark'
    },
    width: 150
  },
  {
    title: '取车地址',
    dataIndex: 'placeInAddress',
    align: 'center',
  },
  {
    title: '取车类型',
    dataIndex: 'pickType.label',
    align: 'center',
    width:80
  },
  {
    title: '起运地交车人',
    dataIndex: 'consignor',
    slots:{
      customRender: 'consignor'
    },
    align: 'center',
  },
  {
    title: '确认运输时间',
    dataIndex: 'confirmTime',
    align: 'center',
  },
  {
    title: '预约时间',
    dataIndex: 'pickAppointTime',
    align: 'center',
  },
 
  {
    title: '录单员',
    dataIndex: 'salesman',
    align: 'center',
    width:80
  },
  {
    title: '调度员',
    dataIndex: 'dispatcher',
    align: 'center',
    width:80
  },
  {
    title: '任务信息',
    dataIndex: 'deliveryCard',
    align: 'center',
    slots: {
      customRender: 'deliveryCard'
    },
    width: 150
  },

  {
    title: '验车人',
    dataIndex: 'inspectionOperator',
    align: 'center',
    width:80
  },
  {
    title: '验车完成时间',
    dataIndex: 'inspectionDoneTime',
    align: 'center',
  },
  {
    title: '标签',
    dataIndex: 'labelName',
    align: 'center',
    width:100
  },
  {
    title: '超时信息',
    dataIndex: 'overTimeTip',
    align: 'center',
    width:100
  },
  {
    title: '台量类型',
    dataIndex: 'vehicleNum.label',
    align: 'center',
    width: 80
  }
])
export const carryiptData2 = ref([
  {
    type: "address",
    placeholder: "取车城市",
    value: null,
    prop: "placeInAddress",
    width: 200,
    changeOn: true
  },
  {
    type: "select",
    placeholder: "是否外派",
    value: null,
    prop: "isTask",
    width: 200,
    opt: [
      {
        name: '是',
        id: 1
      },
      {
        name: '否',
        id: 0
      }
    ]
  },
  {
    type: "select",
    placeholder: "标签类型",
    value: null,
    prop: "labelId",
    width: 200,

  },
  {
    type: "select",
    placeholder: "取车类型",
    value: null,
    prop: "pickType",
    width: 200,
    opt: [
      {
        name: '小板',
        id: 2
      },
      {
        name: '地跑',
        id: 3
      }
    ]
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "orderId",
    width: 200,
  },
  {
    type: "input",
    placeholder: "录单员",
    value: null,
    prop: "salesman",
    width: 200,
  },
  {
    type: "input",
    placeholder: "调度员",
    value: null,
    prop: "dispatcher",
    width: 200,
  },
  {
    type: "input",
    placeholder: "调度备注",
    value: null,
    prop: "dispatchRemark",
    width: 200,
  },
  {
    type: "input",
    placeholder: "承运商",
    value: null,
    prop: "scheduleCarrierName",
    width: 200,
  },
  {
    type: "input",
    placeholder: "安排人",
    value: null,
    prop: "arranger",
    width: 200,
  },
  {
    type: "input",
    placeholder: "小车车牌号",
    value: null,
    prop: "vinNo",
    width: 200,
  },
  {
    type: "selectTime",
    placeholder: "预计发车时间",
    value: null,
    prop: "preDepartureTime",
    width: 200,
  },
  {
    type: "select",
    placeholder: "台量类型",
    value: 1,
    prop: "vehicleNum",
    width: 200,
    opt:[
      {
      id: 1,
      name: '散车'
      },
      {
      id: 2,
      name: '批量车'
      },
    ]
  },
])
export const carrycolumns2 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    width: 50,
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    fixed: 'left'
  },
  {
    title: '操作',
    dataIndex: 'add',
    width: 70,
    align: 'center',
    slots: {
      customRender: 'add'
    },
    fixed: 'left'
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    slots: {
      customRender: 'vinNo'
    },
    align: 'center',
    fixed: 'left'
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center',
  },
  {
    title: '调度备注',
    dataIndex: 'dispatchRemark',
    align: 'center',
    slots: {
      customRender: 'dispatchRemark'
    },
    width: 150
  },
  {
    title: '取车地址',
    dataIndex: 'placeInAddress',
    align: 'center',
  },
  {
    title: '取车类型',
    dataIndex: 'pickType.label',
    align: 'center',
    width: 80
  },
  {
    title: '起运地交车人',
    dataIndex: 'consignor',
    align: 'center',
    slots: {
      customRender: 'consignor'
    }
  },
  {
    title: '确认运输时间',
    dataIndex: 'confirmTime',
    align: 'center',
  },
  {
    title: '预约时间',
    dataIndex: 'pickAppointTime',
    align: 'center',
  },
  {
    title: '录单员',
    dataIndex: 'salesman',
    align: 'center',
    width: 80
  },
  {
    title: '调度员',
    dataIndex: 'dispatcher',
    align: 'center',
    width: 80
  },
  {
    title: '任务信息',
    dataIndex: 'deliveryCard',
    align: 'center',
    slots: {
      customRender: 'deliveryCard'
    },
    width: 150
  },
  
  {
    title: '预计发车时间',
    dataIndex: 'preDepartureTime',
    align: 'center',
    width: 110
  },
  {
    title: '安排信息',
    dataIndex: 'arranger',
    slots: {
      customRender: 'arranger'
    },
    align: 'center',
  },
  {
    title: '承运商',
    dataIndex: 'scheduleCarrierName',
    align: 'center',
  },
  {
    title: '标签',
    dataIndex: 'labelName',
    align: 'center',
    width: 100
  },
  {
    title: '超时信息',
    dataIndex: 'overTimeTip',
    align: 'center',
    width: 100
  },
  {
    title: '台量类型',
    dataIndex: 'vehicleNum.label',
    align: 'center',
    width: 80
  }
])
export const carryiptData3 = ref([
  {
    type: "address",
    placeholder: "取车城市",
    value: null,
    prop: "placeInAddress",
    width: 200,
    changeOn: true
  },
  {
    type: "select",
    placeholder: "是否外派",
    value: null,
    prop: "isTask",
    width: 200,
    opt: [
      {
        name: '是',
        id: 1
      },
      {
        name: '否',
        id: 0
      }
    ]
  },
  {
    type: "select",
    placeholder: "标签类型",
    value: null,
    prop: "labelId",
    width: 200,
  },
  {
    type: "select",
    placeholder: "取车类型",
    value: null,
    prop: "pickType",
    width: 200,
    opt: [
      {
        name: '小板',
        id: 2
      },
      {
        name: '地跑',
        id: 3
      }
    ]
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "orderId",
    width: 200,
  },
  {
    type: "input",
    placeholder: "录单员",
    value: null,
    prop: "salesman",
    width: 200,
  },
  {
    type: "input",
    placeholder: "调度员",
    value: null,
    prop: "dispatcher",
    width: 200,
  },
  {
    type: "input",
    placeholder: "调度备注",
    value: null,
    prop: "dispatchRemark",
    width: 200,
  },
  {
    type: "input",
    placeholder: "发车人",
    value: null,
    prop: "arranger",
    width: 200,
  },
  {
    type: "input",
    placeholder: "承运商",
    value: null,
    prop: "scheduleCarrierName",
    width: 200,
  },
  {
    type: "input",
    placeholder: "小车车牌号",
    value: null,
    prop: "vinNo",
    width: 200,
  },
  {
    type: "select",
    placeholder: "台量类型",
    value: 1,
    prop: "vehicleNum",
    width: 200,
    opt:[
      {
      id: 1,
      name: '散车'
      },
      {
      id: 2,
      name: '批量车'
      },
    ]
  },
])
export const carrycolumns3 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    width: 50,
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    fixed: 'left'
  },
  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    width: 70,
    slots: {
      customRender: 'add'
    },
    fixed: 'left'
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    slots: {
      customRender: 'vinNo'
    },
    align: 'center',
    fixed: 'left'
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center',
  },
  {
    title: '调度备注',
    dataIndex: 'dispatchRemark',
    align: 'center',
    slots: {
      customRender: 'dispatchRemark'
    },
    width: 150
  },
  {
    title: '取车地址',
    dataIndex: 'placeInAddress',
    align: 'center',
  },
  {
    title: '取车类型',
    dataIndex: 'pickType.label',
    align: 'center',
    width: 80
  },
  {
    title: '起运地交车人',
    dataIndex: 'consignor',
    slots: {
      customRender: "consignor"
    },
    align: 'center',
  },
  {
    title: '确认运输时间',
    dataIndex: 'confirmTime',
    align: 'center',
  },
  {
    title: '预约时间',
    dataIndex: 'pickAppointTime',
    align: 'center',
  },
  {
    title: '录单员',
    dataIndex: 'salesman',
    align: 'center',
    width: 80
  },
  {
    title: '调度员',
    dataIndex: 'dispatcher',
    align: 'center',
    width: 80
  },
  {
    title: '任务信息',
    dataIndex: 'deliveryCard',
    align: 'center',
    slots: {
      customRender: 'deliveryCard'
    },
    width: 150
  },
  
  {
    title: '发车人',
    dataIndex: 'departure',
    align: 'center',
    width: 80
  },
  {
    title: '发车时间',
    dataIndex: 'departureTime',
    align: 'center',
  },
  {
    title: '承运商',
    dataIndex: 'departureCarrierName',
    align: 'center',
  },
  {
    title: '标签',
    dataIndex: 'labelName',
    align: 'center',
    width: 100
  },
  {
    title: '超时信息',
    dataIndex: 'overTimeTip',
    align: 'center',
    width: 100
  },
  {
    title: '台量类型',
    dataIndex: 'vehicleNum.label',
    align: 'center',
    width: 80
  }
])
export const carryiptData4 = ref([
  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "startAddress",
    width: 200,
    changeOn: true
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    prop: "endAddress",
    width: 200,
    changeOn: true
  },
  {
    type: "select",
    placeholder: "是否外派",
    value: null,
    prop: "isTask",
    width: 200,
    opt: [
      {
        name: '是',
        id: 1
      },
      {
        name: '否',
        id: 0
      }
    ]
  },
  {
    type: "select",
    placeholder: "标签类型",
    value: null,
    prop: "labelId",
    width: 200,
  },
  {
    type: "address",
    placeholder: "当前城市",
    value: null,
    prop: "nowCityId",
    width: 200,
    changeOn: true
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "orderId",
    width: 200,
  },
  {
    type: "input",
    placeholder: "录单员",
    value: null,
    prop: "salesman",
    width: 200,
  },
  {
    type: "input",
    placeholder: "调度员",
    value: null,
    prop: "dispatcher",
    width: 200,
  },
  {
    type: "input",
    placeholder: "调度备注",
    value: null,
    prop: "dispatchRemark",
    width: 200,
  },
  {
    type: "input",
    placeholder: "小车车牌号",
    value: null,
    prop: "vinNo",
    width: 200,
  },
  {
    type: "selectTime",
    placeholder: "预计发车时间",
    value: null,
    prop: "preDepartureTime",
    width: 200,
  },
  {
    type: "select",
    placeholder: "台量类型",
    value: 1,
    prop: "vehicleNum",
    width: 200,
    opt:[
      {
      id: 1,
      name: '散车'
      },
      {
      id: 2,
      name: '批量车'
      },
    ]
  },
  {
    type: "select",
    placeholder: "是否打标签",
    value: null,
    prop: "isLabel",
    width: 200,
    opt:[
      {
      id: 1,
      name: '是'
      },
      {
      id: 0,
      name: '否'
      },
    ]
  },
  {
    type: "select",
    placeholder: "是否中途卸车",
    value: null,
    prop: "isPit",
    width: 200,
    opt:[
      {
      id: 1,
      name: '是'
      },
      {
      id: 0,
      name: '否'
      },
    ]
  },
])
export const carrycolumns4 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    width: 50,
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    fixed: 'left'
  },
  {
    title: '操作',
    dataIndex: 'add',
    width: 70,
    align: 'center',
    slots: {
      customRender: 'add'
    },
    fixed: 'left'
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    slots: {
      customRender: 'vinNo'
    },
    align: 'center',
    fixed: 'left'
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center',
  },
  {
    title: '调度备注',
    dataIndex: 'dispatchRemark',
    align: 'center',
    slots: {
      customRender: 'dispatchRemark'
    },
    width: 150
  },
  {
    title: '起-终',
    dataIndex: 'startAddress',
    slots: {
      customRender: 'startAddress'
    },
    align: 'center',
  },
 
  {
    title: '录单员',
    dataIndex: 'salesman',
    align: 'center',
    width: 80
  },
  {
    title: '调度员',
    dataIndex: 'dispatcher',
    align: 'center',
    width: 80
  },
  {
    title: '上段完成时间',
    dataIndex: 'lastUnloadTime',
    align: 'center',
  },
  {
    title: '当前城市',
    dataIndex: 'nowCity',
    align: 'center',
  },
  {
    title: '结算信息',
    dataIndex: 'settlementStatus',
    align: 'center',
    slots: {
      customRender: 'settlementStatus'
    },
  },
  
  {
    title: '预计发车时间',
    dataIndex: 'preDepartureTime',
    align: 'center',
  },
  {
    title: '标签',
    dataIndex: 'labelName',
    align: 'center',
    width: 100
  },
  {
    title: '超时信息',
    dataIndex: 'overTimeTip',
    align: 'center',
    width: 100
  },
  {
    title: '台量类型',
    dataIndex: 'vehicleNum.label',
    align: 'center',
    width: 80
  }
])
export const carryiptData5 = ref([
  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "startAddress",
    width: 200,
    changeOn: true
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    prop: "endAddress",
    width: 200,
    changeOn: true
  },
  {
    type: "select",
    placeholder: "是否外派",
    value: null,
    prop: "isTask",
    width: 200,
    opt: [
      {
        name: '是',
        id: 1
      },
      {
        name: '否',
        id: 0
      }
    ]
  },
  {
    type: "select",
    placeholder: "标签类型",
    value: null,
    prop: "labelId",
    width: 200,
  },
  {
    type: "address",
    placeholder: "当前城市",
    value: null,
    prop: "nowCityId",
    width: 200,
    changeOn: true
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "orderId",
    width: 200,
  },
  {
    type: "input",
    placeholder: "录单员",
    value: null,
    prop: "salesman",
    width: 200,
  },
  {
    type: "input",
    placeholder: "调度员",
    value: null,
    prop: "dispatcher",
    width: 200,
  },
  {
    type: "input",
    placeholder: "调度备注",
    value: null,
    prop: "dispatchRemark",
    width: 200,
  },
  {
    type: "input",
    placeholder: "承运商",
    value: null,
    prop: "scheduleCarrierName",
    width: 200,
  },
  {
    type: "input",
    placeholder: "安排人",
    value: null,
    prop: "arranger",
    width: 200,
  },
  {
    type: "input",
    placeholder: "小车车牌号",
    value: null,
    prop: "vinNo",
    width: 200,
  },
  {
    type: "selectTime",
    placeholder: "预计发车时间",
    value: null,
    prop: "preDepartureTime",
    width: 200,
  },
  {
    type: "select",
    placeholder: "台量类型",
    value: 1,
    prop: "vehicleNum",
    width: 200,
    opt:[
      {
      id: 1,
      name: '散车'
      },
      {
      id: 2,
      name: '批量车'
      },
    ]
  },
])
export const carrycolumns5 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    width: 50,
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    fixed: 'left'
  },
  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    width: 70,
    slots: {
      customRender: 'add'
    },
    fixed: 'left'
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    slots: {
      customRender: 'vinNo'
    },
    align: 'center',
    fixed: 'left'
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center',
  },
  {
    title: '调度备注',
    dataIndex: 'dispatchRemark',
    align: 'center',
    slots: {
      customRender: 'dispatchRemark'
    },
    width: 150
  },
  {
    title: '起-终',
    dataIndex: 'startAddress',
    slots: {
      customRender: 'startAddress'
    },
    align: 'center',
  },
  {
    title: '录单员',
    dataIndex: 'salesman',
    align: 'center',
    width: 80
  },
  {
    title: '调度员',
    dataIndex: 'dispatcher',
    align: 'center',
    width: 80
  },
  {
    title: '任务信息',
    dataIndex: 'deliveryCard',
    align: 'center',
    slots: {
      customRender: 'deliveryCard'
    },
    width: 150
  },
  {
    title: '预计发车时间',
    dataIndex: 'preDepartureTime',
    align: 'center',
    width: 110
  },
  {
    title: '安排时间',
    dataIndex: 'scheduleTime',
    align: 'center',
  },
  {
    title: '安排人',
    dataIndex: 'arranger',
    align: 'center',
    width: 100
  },
  {
    title: '大车车牌',
    dataIndex: 'transportNo',
    align: 'center',
    slots: {
      customRender: 'transportNo'
    },
    width: 100
  },
  {
    title: '结算信息',
    dataIndex: 'settlementStatus',
    align: 'center',
    slots: {
      customRender: 'settlementStatus'
    },
  },
  {
    title: '承运商',
    dataIndex: 'scheduleCarrierName',
    align: 'center',
  },
  {
    title: '标签',
    dataIndex: 'labelName',
    align: 'center',
    width: 100
  },
  {
    title: '超时信息',
    dataIndex: 'overTimeTip',
    align: 'center',
    width: 100
  },
  {
    title: '台量类型',
    dataIndex: 'vehicleNum.label',
    align: 'center',
    width: 80
  }
])
export const carryiptData6 = ref([
  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "startAddress",
    width: 200,
    changeOn: true
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    prop: "endAddress",
    width: 200,
    changeOn: true
  },
  {
    type: "select",
    placeholder: "是否外派",
    value: null,
    prop: "isTask",
    width: 200,
    opt: [
      {
        name: '是',
        id: 1
      },
      {
        name: '否',
        id: 0
      }
    ]
  },
  {
    type: "select",
    placeholder: "标签类型",
    value: null,
    prop: "labelId",
    width: 200,
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "orderId",
    width: 200,
  },
  {
    type: "input",
    placeholder: "录单员",
    value: null,
    prop: "salesman",
    width: 200,
  },
  {
    type: "input",
    placeholder: "调度员",
    value: null,
    prop: "dispatcher",
    width: 200,
  },
  {
    type: "input",
    placeholder: "发车人",
    value: null,
    prop: "departure",
    width: 200,
  },
  {
    type: "input",
    placeholder: "调度备注",
    value: null,
    prop: "dispatchRemark",
    width: 200,
  },
  {
    type: "input",
    placeholder: "承运商",
    value: null,
    prop: "departureCarrierName",
    width: 200,
  },
  {
    type: "input",
    placeholder: "小车车牌号",
    value: null,
    prop: "vinNo",
    width: 200,
  },
  {
    type: "select",
    placeholder: "台量类型",
    value: 1,
    prop: "vehicleNum",
    width: 200,
    opt:[
      {
      id: 1,
      name: '散车'
      },
      {
      id: 2,
      name: '批量车'
      },
    ]
  },
])
export const carrycolumns6 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    width: 50,
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    fixed: 'left'
  },
  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    width: 70,
    slots: {
      customRender: 'add'
    },
    fixed: 'left'
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    slots: {
      customRender: 'vinNo'
    },
    align: 'center',
    fixed: 'left'
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center',
  },
  {
    title: '调度备注',
    dataIndex: 'dispatchRemark',
    align: 'center',
    slots: {
      customRender: 'dispatchRemark'
    },
    width: 150
  },
  {
    title: '起-终',
    dataIndex: 'startAddress',
    slots: {
      customRender: 'startAddress'
    },
    align: 'center',
  },

  {
    title: '录单员',
    dataIndex: 'salesman',
    align: 'center',
    width: 80
  },
  {
    title: '调度员',
    dataIndex: 'dispatcher',
    align: 'center',
    width: 80
  },
  {
    title: '当前位置',
    dataIndex: 'nowLocation',
    align: 'center',
  },
  {
    title: '发车时间',
    dataIndex: 'departureTime',
    align: 'center',
  },
  {
    title: '发车人',
    dataIndex: 'departure',
    align: 'center',
    width: 80
  },
  {
    title: '任务信息',
    dataIndex: 'deliveryCard',
    align: 'center',
    slots: {
      customRender: 'deliveryCard'
    },
    width: 150
  },
  {
    title: '大车车牌',
    dataIndex: 'transportNo',
    slots: {
      customRender: 'transportNo'
    },
    align: 'center',
    width: 100
  },
  {
    title: '结算信息',
    dataIndex: 'settlementStatus', 
    align: 'center',
    slots: {
      customRender: 'settlementStatus'
    },
  },
  {
    title: '承运商',
    dataIndex: 'departureCarrierName',
    align: 'center',
  },
  {
    title: '标签',
    dataIndex: 'labelName',
    align: 'center',
    width: 100
  },
  {
    title: '超时信息',
    dataIndex: 'overTimeTip',
    align: 'center',
    width: 100
  },
  {
    title: '台量类型',
    dataIndex: 'vehicleNum.label',
    align: 'center',
    width: 80
  }
])
export const carryiptData7 = ref([
  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "startAddress",
    width: 200,
    changeOn: true
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    prop: "endAddress",
    width: 200,
    changeOn: true
  },
  {
    type: "select",
    placeholder: "是否外派",
    value: null,
    prop: "isTask",
    width: 200,
    opt: [
      {
        name: '是',
        id: 1
      },
      {
        name: '否',
        id: 0
      }
    ]
  },
  {
    type: "select",
    placeholder: "标签类型",
    value: null,
    prop: "labelId",
    width: 200,
  },
  {
    type: "select",
    placeholder: "送车类型",
    value: null,
    prop: "takeType",
    width: 200,
    opt: [
      {
        name: '小板',
        id: 2
      },
      {
        name: '地跑',
        id: 3
      }
    ]
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "orderId",
    width: 200,
  },
  {
    type: "input",
    placeholder: "录单员",
    value: null,
    prop: "salesman",
    width: 200,
  },
  {
    type: "input",
    placeholder: "调度员",
    value: null,
    prop: "dispatcher",
    width: 200,
  },
  {
    type: "input",
    placeholder: "调度备注",
    value: null,
    prop: "dispatchRemark",
    width: 200,
  },
  {
    type: "input",
    placeholder: "车牌号",
    value: null,
    prop: "vinNo",
    width: 200,
  },
  {
    type: "select",
    placeholder: "台量类型",
    value: 1,
    prop: "vehicleNum",
    width: 200,
    opt:[
      {
      id: 1,
      name: '散车'
      },
      {
      id: 2,
      name: '批量车'
      },
    ]
  },
])
export const carrycolumns7 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    width: 50,
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    fixed: 'left'
  },
  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    width: 70,
    slots: {
      customRender: 'add'
    },
    fixed: 'left'
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    slots: {
      customRender: 'vinNo'
    },
    align: 'center',
    fixed: 'left'
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center',
  },
  {
    title: '调度备注',
    dataIndex: 'dispatchRemark',
    align: 'center',
    slots: {
      customRender: 'dispatchRemark'
    },
    width: 150
  },
  {
    title: '送车地址',
    dataIndex: 'takeOutAddress',
    slots: {
      customRender: 'takeOutAddress'
    },
    align: 'center',
  },
  {
    title: '送车类型',
    dataIndex: 'takeType.label',
    align: 'center',
    width: 80
  },
  {
    title: '目的地取车人',
    dataIndex: 'picker',
    align: 'center',
    slots: {
      customRender: 'picker'
    },
    width: 110
  },
 
  {
    title: '录单员',
    dataIndex: 'salesman',
    align: 'center',
    width: 80
  },
  {
    title: '调度员',
    dataIndex: 'dispatcher',
    align: 'center',
    width: 80
  },
  {
    title: '任务信息',
    dataIndex: 'deliveryCard',
    align: 'center',
    slots: {
      customRender: 'deliveryCard'
    },
    width: 150
  },
 
  {
    title: '上段卸车时间',
    dataIndex: 'lastUnloadTime',
    align: 'center',
    width:130
  },
  {
    title: '结算信息',
    dataIndex: 'settlementStatus',
    align: 'center',
    slots: {
      customRender: 'settlementStatus'
    },
  },
  {
    title: '标签',
    dataIndex: 'labelName',
    align: 'center',
    width: 100
  },
  {
    title: '超时信息',
    dataIndex: 'overTimeTip',
    align: 'center',
    width: 100
  },
  {
    title: '台量类型',
    dataIndex: 'vehicleNum.label',
    align: 'center',
    width: 80
  }
])
export const carryiptData8 = ref([
  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "startAddress",
    width: 200,
    changeOn: true
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    prop: "endAddress",
    width: 200,
    changeOn: true
  },
  {
    type: "select",
    placeholder: "是否外派",
    value: null,
    prop: "isTask",
    width: 200,
    opt: [
      {
        name: '是',
        id: 1
      },
      {
        name: '否',
        id: 0
      }
    ]
  },
  {
    type: "select",
    placeholder: "标签类型",
    value: null,
    prop: "labelId",
    width: 200,
  },
  {
    type: "select",
    placeholder: "送车类型",
    value: null,
    prop: "takeType",
    width: 200,
    opt: [
      {
        name: '小板',
        id: 2
      },
      {
        name: '地跑',
        id: 3
      }
    ]
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "orderId",
    width: 200,
  },
  {
    type: "input",
    placeholder: "录单员",
    value: null,
    prop: "salesman",
    width: 200,
  },
  {
    type: "input",
    placeholder: "调度员",
    value: null,
    prop: "dispatcher",
    width: 200,
  },
  {
    type: "input",
    placeholder: "调度备注",
    value: null,
    prop: "dispatchRemark",
    width: 200,
  },
  {
    type: "input",
    placeholder: "承运商",
    value: null,
    prop: "scheduleCarrierName",
    width: 200,
  },
  {
    type: "input",
    placeholder: "小车车牌号",
    value: null,
    prop: "vinNo",
    width: 200,
  },
  {
    type: "selectTime",
    placeholder: "预计发车时间",
    value: null,
    prop: "preDepartureTime",
    width: 200,
  },
  {
    type: "select",
    placeholder: "台量类型",
    value: 1,
    prop: "vehicleNum",
    width: 200,
    opt:[
      {
      id: 1,
      name: '散车'
      },
      {
      id: 2,
      name: '批量车'
      },
    ]
  },
])
export const carrycolumns8 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    width: 50,
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    fixed: 'left'
  },
  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    width: 70,
    slots: {
      customRender: 'add'
    },
    fixed: 'left'
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    slots: {
      customRender: 'vinNo'
    },
    align: 'center',
    fixed: 'left'
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center',
  },
  {
    title: '调度备注',
    dataIndex: 'dispatchRemark',
    align: 'center',
    slots: {
      customRender: 'dispatchRemark'
    },
    width: 150
  },
  {
    title: '送车地址',
    dataIndex: 'takeOutAddress',
    slots: {
      customRender: 'takeOutAddress'
    },
    align: 'center',
  },
  {
    title: '送车类型',
    dataIndex: 'takeType.label',
    align: 'center',
    width: 80
  },
  {
    title: '目的地交车人',
    dataIndex: 'picker',
    align: 'center',
    slots: {
      customRender: 'picker'
    },
    width: 110
  },
 
  {
    title: '录单员',
    dataIndex: 'salesman',
    align: 'center',
    width: 80
  },
  {
    title: '调度员',
    dataIndex: 'dispatcher',
    align: 'center',
    width: 80
  },
  {
    title: '任务信息',
    dataIndex: 'deliveryCard',
    align: 'center',
    slots: {
      customRender: 'deliveryCard'
    },
    width: 150
  },

  {
    title: '预计发车时间',
    dataIndex: 'preDepartureTime',
    align: 'center',
    width: 110
  },
  {
    title: '安排信息',
    dataIndex: 'arranger',
    slots: {
      customRender: 'arranger'
    },
    align: 'center',
  },
  {
    title: '承运商',
    dataIndex: 'scheduleCarrierName',
    align: 'center',
  },
  {
    title: '结算信息',
    dataIndex: 'settlementStatus',
    align: 'center',
    slots: {
      customRender: 'settlementStatus'
    },
  },

  {
    title: '标签',
    dataIndex: 'lableName',
    align: 'center',
    width: 100
  },
  {
    title: '超时信息',
    dataIndex: 'overTimeTip',
    align: 'center',
    width: 100
  },
  {
    title: '台量类型',
    dataIndex: 'vehicleNum.label',
    align: 'center',
    width: 80
  }
])
export const carryiptData9 = ref([
  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "startAddress",
    width: 200,
    changeOn: true
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    prop: "endAddress",
    width: 200,
    changeOn: true
  },
  {
    type: "select",
    placeholder: "是否外派",
    value: null,
    prop: "isTask",
    width: 200,
    opt: [
      {
        name: '是',
        id: 1
      },
      {
        name: '否',
        id: 0
      }
    ]
  },
  {
    type: "select",
    placeholder: "标签类型",
    value: null,
    prop: "labelId",
    width: 200,
  },
  {
    type: "select",
    placeholder: "送车类型",
    value: null,
    prop: "takeType",
    width: 200,
    opt: [
      {
        name: '小板',
        id: 2
      },
      {
        name: '地跑',
        id: 3
      }
    ]
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "orderId",
    width: 200,
  },
  {
    type: "input",
    placeholder: "录单员",
    value: null,
    prop: "salesman",
    width: 200,
  },
  {
    type: "input",
    placeholder: "调度员",
    value: null,
    prop: "dispatcher",
    width: 200,
  },
  {
    type: "input",
    placeholder: "调度备注",
    value: null,
    prop: "dispatchRemark",
    width: 200,
  },
  {
    type: "input",
    placeholder: "承运商",
    value: null,
    prop: "departureCarrierName",
    width: 200,
  },
  {
    type: "input",
    placeholder: "发车人",
    value: null,
    prop: "departure",
    width: 200,
  },
  {
    type: "input",
    placeholder: "小车车牌号",
    value: null,
    prop: "vinNo",
    width: 200,
  },
  {
    type: "select",
    placeholder: "台量类型",
    value: 1,
    prop: "vehicleNum",
    width: 200,
    opt:[
      {
      id: 1,
      name: '散车'
      },
      {
      id: 2,
      name: '批量车'
      },
    ]
  },
])
export const carrycolumns9 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    width: 50,
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    fixed: 'left'
  },
  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    width: 70,
    slots: {
      customRender: 'add'
    },
    fixed: 'left'
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    slots: {
      customRender: 'vinNo'
    },
    align: 'center',
    fixed: 'left'
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center',
  },
  {
    title: '调度备注',
    dataIndex: 'dispatchRemark',
    align: 'center',
    slots: {
      customRender: 'dispatchRemark'
    },
    width: 150
  },
  {
    title: '送车地址',
    dataIndex: 'takeOutAddress',
    slots: {
      customRender: 'takeOutAddress'
    },
    align: 'center',
  },
  {
    title: '送车类型',
    dataIndex: 'takeType.label',
    align: 'center',
    width: 80
  },
  {
    title: '目的地交车人',
    dataIndex: 'picker',
    align: 'center',
    slots: {
      customRender: 'picker'
    }
  },

  {
    title: '录单员',
    dataIndex: 'salesman',
    align: 'center',
    width: 80
  },
  {
    title: '调度员',
    dataIndex: 'dispatcher',
    align: 'center',
    width: 80
  },
  {
    title: '任务信息',
    dataIndex: 'deliveryCard',
    align: 'center',
    slots: {
      customRender: 'deliveryCard'
    },
    width: 150
  },
 
  {
    title: '发车信息',
    dataIndex: 'departure',
    slots: {
      customRender: 'departure'
    },
    align: 'center',
  },
  {
    title: '承运商',
    dataIndex: 'departureCarrierName',
    align: 'center',
  },
  {
    title: '结算信息',
    dataIndex: 'settlementStatus',
    align: 'center',
    slots: {
      customRender: 'settlementStatus'
    },
  },
  {
    title: '标签',
    dataIndex: 'labelName',
    align: 'center',
    width: 100
  },
  {
    title: '超时信息',
    dataIndex: 'overTimeTip',
    align: 'center',
    width: 100
  },
  {
    title: '台量类型',
    dataIndex: 'vehicleNum.label',
    align: 'center',
    width: 80
  }
])

