<template>
  <div>
    <a-space style="margin-bottom:10px;">
      <a-select style="width: 120px" v-model:value="searchForm.type" placeholder="选择类型" allowClear>
        <a-select-option :value="1">取车</a-select-option>
        <a-select-option :value="2">运车</a-select-option>
        <a-select-option :value="3">送车</a-select-option>
      </a-select>
      <a-select style="width: 120px" v-model:value="searchForm.status" placeholder="选择状态" allowClear>
        <a-select-option :value="0">全部</a-select-option>
        <a-select-option :value="1">新任务</a-select-option>
        <a-select-option :value="2">已安排</a-select-option>
        <a-select-option :value="3">已发运</a-select-option>
        <a-select-option :value="5">中途卸车</a-select-option>
        <a-select-option :value="6">中途发车</a-select-option>
      </a-select>
      <a-input v-model:value="searchForm.carNo" style="width: 120px" placeholder="车牌号" allowClear />
      <a-input v-model:value="searchForm.orderId" placeholder="合同号" allowClear />
      <a-cascader :show-search="{ filter }" :options="$store.state.app.cityDict" :changeOnSelect="true"
        @change="setStartAddress" placeholder="选择起始地" />
      <a-cascader :show-search="{ filter }" :options="$store.state.app.cityDict" :changeOnSelect="true"
        @change="setEndAddress" placeholder="选择目的地" />
    </a-space>
    <a-space style="margin-bottom:10px;display:flex;justify-content: flex-end;">
      <a-button type="primary" @click="smallCar">搜索</a-button>
      <a-button @click="chooseShow = true" :disabled="!transOrderVehicleId.length > 0">批量装车({{ transOrderVehicleId.length
      }})</a-button>
    </a-space>
    <a-table :data-source="listData" :row-selection="{ selectedRowKeys: transOrderVehicleId, onChange: onSelectChange }"
      v-model:columns="Carcolumns" :pagination="pagination" :loading="Carloading"
      :rowKey="(record, index) => { return record.transOrderVehicleId }" size="small" bordered
      @change="handleTableChange">
      <template #model="{ record }">
        <span>{{ record.brand }} {{ record.model }}</span>
      </template>
      <template #vinNo="{ record }">
        <a @click="smallDetail = record, smallDetailShow = true">{{ record.vinNo }}</a>
      </template>
      <template #operation="{ record }">
        <a-button style="background:#F2F2F2;color:#0066CC" size="small" @click="loadCar(record)">装车</a-button>
      </template>
    </a-table>
  </div>
  <!-- 是否到达门店 -->
  <a-modal v-model:visible="chooseShow" title="已选择承运商，请配置到达门店122" @ok="confirmChoose" :confirmLoading="confirmLoading"
    :destroyOnClose="true">
    <a-form>
      <a-form-item label="是否中转">
        <a-radio-group v-model:value="whether" @change="wetherChange">
          <a-radio :value="1">是</a-radio>
          <a-radio :value="0">否</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item v-show="whether === 1" label="卸车地">
        <a-cascader :options="$store.state.app.cityDict" :show-search="{ filter }" :changeOnSelect="true"
          @change="setAddress" placeholder="卸车地选择" />
      </a-form-item>
      <a-form-item v-show="arrivalShow&&type!==2" label="是否到达门店">
        <a-select :options="$store.state.enumAll.BooleanFlagEnum" v-model:value="boolean" style="width: 120px"
          @change="whetherArrival" />
      </a-form-item>
      <a-form-item label="单台运车费用">
        <a-input style="width: 120px" v-model:value="vehiclePrice" />
      </a-form-item>
      <a-form-item label="预计发车时间">
        <a-date-picker :style="{ width: 200 + 'px'}" v-model:value="arrangeLoadTimeStr" placeholder="预计发车时间" />
      </a-form-item>
    </a-form>
  </a-modal>
  <!-- 小车详情 -->
  <a-modal v-model:visible="smallDetailShow" :width="1400" title="小车信息" :destroyOnClose="true">
    <div>
      <TotalDetail :element="smallDetail" :orderId="smallDetail.orderId" :orderVehicleId="smallDetail.orderVehicleId"
        :index="0" />
    </div>
  </a-modal>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import { incompletenew, getOffice } from '@/api/transport/truck'
import TotalDetail from '../detail/totalDetail'
import moment from 'moment'
import { transitionTimeYMD } from '@/utils/util'

export default {
  components: {
    TotalDetail
  },
  props: {
    endCity: Number,
    type:{
      type: Number,
      default: 0
    }
  },
  setup (props, context) {
    const state = reactive({
      unloadings: '',
      vehiclePrice: '',
      arrangeLoadTimeStr:moment(new Date()),
      whether: '',
      endCity: null,
      boolean: null,
      confirmLoading: false,
      smallDetailShow: false,
      arrivalShow: false,
      disabled: false,
      Carloading: false,
      chooseShow: false,
      chooseForm: {},
      smallDetail: {},
      isShop: {},
      listData: [],
      cityList: [],
      transOrderVehicleId: [],
      searchForm: {
        orderId: '',
        carNo: '',
        status: null,
        type: null,
        startAddress: {},
        endAddress: {}
      },
      Carcolumns: [
        {
          title: '运输类型',
          dataIndex: 'transportType.label',
          width: '10%'
        },
        {
          title: '运输状态',
          dataIndex: 'transStatus.label',
          width: '11%'
        },
        {
          title: '车牌号',
          width: '11%',
          slots: {
            customRender: 'vinNo'
          }
        },
        {
          title: '车型',
          width: '9%',
          slots: {
            customRender: 'model'
          }
        },
        {
          title: '是否推送',
          width: '10%',
          dataIndex:'isPush.label'
        },
        {
          title: '合同号',
          dataIndex: 'orderId'
        },
        {
          title: '始发地',
          dataIndex: 'startAddress',
          width: '20%'
        },
        {
          title: '目的地',
          dataIndex: 'endAddress',
          width: '20%'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      pagination: {
        current: 1,
        pageSize: 5,
        pageSizeOptions: ['5', '10'],
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    // 获取可安排小车信息
    const getSureData = () => {
      state.Carloading = true
      incompletenew({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize,
        name: state.vinNo
      }).then((res) => {
        state.listData = res.data.records
        state.pagination.current = res.data.current
        state.pagination.pageSize = res.data.size
        state.pagination.total = res.data.total
        state.Carloading = false
      }).catch(err => { console.log(err) })
    }
    const smallCar = () => {
      state.pagination.current = 1
      getSureData()
    }
    const setStartAddress = (value, selectedOptions) => {
      state.searchForm.startAddress.province = value[0]
      state.searchForm.startAddress.city = value[1]
      state.searchForm.startAddress.area = value[2]
    }

    const setEndAddress = (value, selectedOptions) => {
      state.searchForm.endAddress.province = value[0]
      state.searchForm.endAddress.city = value[1]
      state.searchForm.endAddress.area = value[2]
    }
    // 是否到达门店
    const whetherArrival = (e, v) => {
      state.isShop = v
    }
    // 批量选择装车
    const onSelectChange = selectedRowKeys => {
      state.transOrderVehicleId = selectedRowKeys
    }
    const loadCar = record => {
      state.transOrderVehicleId = []
      state.transOrderVehicleId.push(record.transOrderVehicleId)
      state.chooseShow = true
    }
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      getSureData()
    }
    // 卸车地
    const setAddress = (value, selectedOptions) => {
      state.chooseForm.province = value[0]
      state.chooseForm.city = value[1]
      state.chooseForm.area = value[2]
      if (value.length <= 0) {
        state.chooseForm = {}
        state.unloadings = ''
        state.arrivalShow = false
      }
      switch (selectedOptions.length) {
        case 1:
          state.chooseForm.province = value[0]
          state.unloadings = selectedOptions[0].label
          break
        case 2:
          state.chooseForm.city = Number(value[1])
          state.arrivalShow = true
          const index = state.cityList.findIndex(item => item.cityId === state.chooseForm.city) >= 0
          if (index === false) {
            state.boolean = '否'
            whetherArrival(false, { value: 0, label: '否' })
          }
          state.disabled = !index
          state.unloadings = selectedOptions[0].label + selectedOptions[1].label
          break
        case 3:
          state.chooseForm.area = value[2]
          state.unloadings = selectedOptions[0].label + selectedOptions[1].label + selectedOptions[2].label
          break
      }
    }
    const wetherChange = e => {
      const index = state.cityList.findIndex(item => item.cityId === state.endCity) >= 0
      if (state.whether === 1 && !state.chooseForm.city) state.arrivalShow = false
      if (state.whether === 0) {
        if (index === false) {
          state.boolean = '否'
          whetherArrival(false, {
            value: 0,
            label: '否'
          })
        }
        state.arrivalShow = true
        state.disabled = !index
      } else if (state.chooseForm.city) {
        if ((state.cityList.findIndex(item => item.cityId === state.chooseForm.city) >= 0) === true) {
          state.arrivalShow = true
          state.disabled = false
        } else {
          state.disabled = true
          whetherArrival(false, {
            value: 0,
            label: '否'
          })
        }
      }
    }
    // 确认装车--传值
    const confirmChoose = () => {
      state.confirmLoading = true
      if (state.whether === 2) state.chooseForm = {}
      const data = {
        freight: state.vehiclePrice,
        transit: state.chooseForm,
        transOrderVehicleIds: state.transOrderVehicleId,
        isShop: state.isShop,
        arrangeLoadTimeStr:transitionTimeYMD(state.arrangeLoadTimeStr) 
      }
      if(props.type=== 2){
        data.isShop = {
          value: 0,
          label: '否'
        }
      }
      context.emit('confirmLoad', data)
      state.chooseShow = false
      state.transOrderVehicleId = []
      setTimeout(() => {
        getSureData()
        state.arrivalShow = false
        state.whether = null
        state.boolean = null
        state.vehiclePrice = ''
        state.chooseForm = {}
        state.confirmLoading = false
        state.arrangeLoadTimeStr = moment(new Date())
      }, 1200)
    }
    onMounted(() => {
      if (props.endCity) state.endCity = props.endCity
      getSureData()
      setTimeout(() => {
        getOffice().then(res => {
          if (res.code === 10000) {
            state.cityList = res.data
          }
        }).catch(err => {
          console.log(err)
        })
      }, 300)
    })

    return {
      ...toRefs(state),
      loadCar,
      smallCar,
      setAddress,
      getSureData,
      wetherChange,
      setStartAddress,
      setEndAddress,
      confirmChoose,
      whetherArrival,
      onSelectChange,
      handleTableChange
    }
  }
}
</script>

<style lang="less" scoped></style>
