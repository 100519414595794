<template>
  <div>
    <a-modal :visible="visible" title="交车条" footer="" centered @cancel="cancel" width="80%" :bodyStyle="{ padding:'10px',background:'#EEEEEE'}">
      <div class="statusBox flex">
        <div v-for="item in tabstatus" :key="item.id" class="item cur-p" @click="changeBtn(item)" :class="indexKey === item.colorType ?'changeItem':''">
          {{ item.name }}({{ item.total }})
        </div>
      </div>
      <SearchList :iptData="iptData" :loading="loading" @searchAdd="searchAdd" @resetBtn="resetBtn">
        <template #tail>
          <div>
            <a-button type="primary" @click="BatchSubmit">批量交车</a-button>
          </div>
        </template>
      </SearchList>
      <div class="m-t1 tableBox">
        <div class="sys-screenTitle">当前筛选出&nbsp;<a >{{ data.length }}</a>&nbsp;条</div>
        <a-table ref="tableRef" :dataSource="dataSource" :row-selection="rowSelection"  :columns="columns" size="small" :rowKey="item => item.orderVehicleId" bordered :pagination="false" :scroll="{ y: 500 ,x:1600}" :loading="loading">
          <template #sort="{  index }">
            {{ index + 1 }}
          </template>
          <template #vinNo="{ record }">
            <a ><a-typography-paragraph :copyable="{ text: record.vinNo }"><a @click="mode(record)">{{ record.vinNo }}</a></a-typography-paragraph><div @click="mode(record)" >{{ record.brand }}{{ record.model }}</div></a>
          </template>
          <template #startAddress="{record }">
            {{ record.startAddress }}
            <div>{{ record.endAddress }}</div>
          </template>
          <template #add="{ record }">
            <a @click="visibleCar = true,details = record">交车</a>
          </template>
          <template #dispatchRemark="{ record }">
            <div style="display:flex" @mousemove="remarkId = record.dispatchId">
              <div class="remark-content">
                <a-tooltip color="#fff">
                  <template #title>
                    <span style="color:#000">{{ record.dispatchRemark }}</span>
                  </template>
                  <span>{{ record.dispatchRemark }}</span>
                </a-tooltip>
                <a v-show="!record.dispatchRemark" @click="openDisRemarks(record)">新增调度备注</a>
              </div>
              <div style="display:flex;align-items:center;min-width:30px" class="m-l1">
                <a v-show="record.dispatchRemark" @click="openDisRemarks(record)">记录</a>
              </div>
            </div>
          </template>
        </a-table>
      </div>
      <!-- 交车 -->
      <DeliveryCar v-model:visible="visibleCar" v-if="visibleCar" @success="success" :vehicleId="details.orderVehicleId"></DeliveryCar>
      <TransportInfo ref="transportInfoRef"></TransportInfo>
      <!-- 批量交车 -->
      <a-modal v-model:visible="handInShow" title="批量交车" width="35%" :footer="false"> 
        <HandInVehicle ref="handInVehicleRef" v-if="handInShow" :vehicleArr="batchGiveList" @statusShow="handInStatus" />
      </a-modal>
        <!-- 新增/编辑调度备注 -->
        <SchedulingLogs @setRemaekOk="setRemaekOk" :remarkId="remarkId" :isOverTime="isOverTime" v-if="remarkShow" v-model:visible="remarkShow" :orderVehicleId="logsVehicleId" />
    </a-modal>
  </div>
</template>
  
  <script setup>
import { ref, onMounted, nextTick, reactive } from 'vue'
import SearchList from '@/components/searchList'
import DeliveryCar from '@/components/DeliveryCar'
import { deliveryCheck } from '@/api/newTransport/newTransport'
import { batchDelivery as batchDeliveryData } from '@/api/transport/transport'
import TransportInfo from '@/components/transportInfo'
import { uploadVehicle as upload } from '@/api/global'
import { useStore } from 'vuex'
import { addressTran, IfNull } from '@/utils/util'
import { cloneDeep } from 'lodash-es'
import HandInVehicle from '@/views/components/transport/comon//handInVehicle.vue'
import { message } from 'ant-design-vue'
import SchedulingLogs from '@/views/components/transport/smallVehicleOperate/schedulingLogs.vue'


const store = useStore()
const emit = defineEmits(['update:visible'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  info: {
    type: Object,
    default: {}
  },
  tableIdx: {
    type: Number,
    default: null
  },
  vehicleNumType: {
    type: Number,
    default: 0
  }
})

const tableRef = ref(null)
const handInVehicleRef = ref(null)
const batchGiveList = ref([])
const handInShow = ref(false)
const indexKey = ref(null)
const visibleCar = ref(false)
const dataSource = ref([])
const data = ref([])
const loading = ref(false)
const transportInfoRef = ref(null)
const details = ref(null)
const searchMsg = ref({
  vehicleNum:1
})
const remarkId = ref('')
const logsVehicleId = ref(null)
const remarkShow = ref(false)
const isOverTime = reactive({
  isOverTime: '',
  transportType: '',
  taskType: '',
})
const pageSize = ref(50)

const openDisRemarks = (record) => {
  isOverTime.isOverTime = record.isOverTime.value
  isOverTime.transportType = record.transportType.value
  isOverTime.taskType = record.taskType
  remarkShow.value = true
  logsVehicleId.value = record.orderVehicleId
}
const setRemaekOk = (str, label) => {
  if (IfNull(str)) {
    data.value.forEach(item => {
      if (item.dispatchId == remarkId.value) {
        item.dispatchRemark = str;
      }
    })
  }
  if (label) {
    data.value.forEach(item => {
      if (item.dispatchId == remarkId.value) {
        item.labelName = label;
      }
    })
  }
}
// 交车
const handInStatus = e => {
  handInVehicleRef.value.confirmLoading = true
  if (!e.formData) handInShow.value = false
  else {
    handInVehicleRef.value.confirmLoading = true
    let orderVehicleIds = batchGiveList.value.map(item => item.orderVehicleId)
    upload(e.formData).then(res => {
      if (res.code === 10000) {
        let fileId = res.data.id
        let submit = {
          deliveryOfficeOrg: e.deliveryOfficeOrg,
          deliveryType: e.deliveryType,
          officeOrgId: e.officeOrgId,
          remark: e.remark,
          fileId: fileId,
          orderVehicleIds: orderVehicleIds
        }
        batchDeliveryData(submit).then(res => {
          if (res.code === 10000) {
            message.success('交车成功')
            handInVehicleRef.value.cancelClose()
            handInShow.value = false
            reqDeliveryCheck()
          }
        }).finally(() => {
          if (handInVehicleRef.value) {
            handInVehicleRef.value.confirmLoading = false
          }
        })
      }
    })
  }
}
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    batchGiveList.value = selectedRows
  },
}
const BatchSubmit = () => {
  if(batchGiveList.value.length === 0){
     message.warning('请选择车辆')
     return
  }
  handInShow.value = true
}
const searchAdd = (e) => {
  ResetScroll()
  let msg = cloneDeep(e)
  msg.startAddress = addressTran(msg.startAddress)
  msg.endAddress = addressTran(msg.endAddress)
  searchMsg.value = msg
  reqDeliveryCheck()
}
const resetBtn = () => {
  ResetScroll()
  searchMsg.value = {
    vehicleNum:props.vehicleNumType
  }
  reqDeliveryCheck()
}
const cancel = () => {
  emit('update:visible', false)
}
const mode = (e) => {
  transportInfoRef.value.detail = e
  transportInfoRef.value.detailShow = true
  setTimeout(() => {
    transportInfoRef.value.okk()
  }, 50)
}
const changeBtn = (e) => {
  ResetScroll()
  indexKey.value = e.colorType
  reqDeliveryCheck()
}
const success = () => {
  visibleCar.value = false
  reqDeliveryCheck()
}
const reqDeliveryCheck = () => {

  loading.value = true
  deliveryCheck({
    ...searchMsg.value,
    subType: indexKey.value
  }).then(res => {
    if (res.code !== 10000) return
    data.value = res.data ? res.data : []
    dataSource.value = data.value.slice(0, pageSize.value)
  }).finally(() => {
    loading.value = false
  })
}
const ResetScroll = () => {
  pageSize.value = 50
  dataSource.value = []
}
onMounted(() => {
  if (props.vehicleNumType === 2) {
    searchMsg.value = {
      vehicleNum: props.vehicleNumType
    },
    iptData.value.forEach(item => {
      if(item.prop === 'vehicleNum'){
        item.value = props.vehicleNumType
      }
    })
  }
  if (props.info) {
    tabstatus.value[0].total = props.info.num
    if (props.info.subItemList) {
      props.info.subItemList.forEach((opt, index) => {
        tabstatus.value[index + 1].total = opt.num
        tabstatus.value[index + 1].colorType = opt.colorType
      })
    }
  }
  indexKey.value = props.tableIdx
  reqDeliveryCheck()
  nextTick(() => {
    const tableBody = tableRef.value.$el.querySelector('.ant-table-body');
    tableBody.addEventListener('scroll', (event) => {
      if (dataSource.value.length === 0) return
      if (pageSize.value > data.value.length) return
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      if (scrollTop + clientHeight >= scrollHeight - 100) {
        // 滚动条触底，执行加载数据的操作
        pageSize.value += 50
        dataSource.value = data.value.slice(0, pageSize.value)
        // 这里可以调用你的加载数据函数
      }
    });
  })
})
const tabstatus = ref([
  {
    name: '全部',
    id: 0,
    colorType: null,
    total: 0
  },
  {
    name: '今日待上传',
    id: 1,
    total: 0
  },
  {
    name: '遗留未上传',
    id: 2,
    total: 0
  },
])
const iptData = ref([
  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "startAddress",
    width: 200,
    changeOn: true
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    prop: "endAddress",
    width: 200,
    changeOn: true
  },
  {
    type: "select",
    placeholder: "结算状态",
    value: null,
    prop: "settlementStatus",
    width: 200,
    opt: [
      { name: '未结算', id: 0 },
      { name: '部分结算', id: 1 },
      { name: '已结算', id: 2 }
    ]
  },
  {
    type: "select",
    placeholder: "标签类型",
    value: null,
    prop: "labelId",
    width: 200,
    opt: store.state.app.labelList
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "orderId",
    width: 200,
  },
  {
    type: "input",
    placeholder: "录单员",
    value: null,
    prop: "salesman",
    width: 200,
  },
  {
    type: "input",
    placeholder: "卸车人",
    value: null,
    prop: "lastOperator",
    width: 200,
  },
  {
    type: "input",
    placeholder: "车牌号",
    value: null,
    prop: "vinNo",
    width: 200,
  },
  {
    type: "select",
    placeholder: "台量类型",
    value: 1,
    prop: "vehicleNum",
    width: 200,
    opt:[
      {
      id: 1,
      name: '散车'
      },
      {
      id: 2,
      name: '批量车'
      },
    ]
  },
])
const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    width: 50,
    fixed:'left'
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    align: 'center',
    slots: {
      customRender: 'vinNo'
    },
    fixed:'left'
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center',
    width:180
  },
  {
    title: '调度备注',
    dataIndex: 'dispatchRemark',
    slots: {
      customRender: 'dispatchRemark'
    },
    align: 'center',
    width: 150
  },
  {
    title: '卸车时间',
    dataIndex: 'lastTime',
    align: 'center',
    width:150
  },
  {
    title: '卸车人',
    dataIndex: 'lastOperator',
    align: 'center',
    width: 70,
  },
  {
    title: '卸车地',
    dataIndex: 'lastAddress',
    align: 'center',
  },
  {
    title: '卸车责任对象',
    dataIndex: 'lastCreditor',
    align: 'center',
  },
  {
    title: '起-终',
    dataIndex: 'startAddress',
    slots: {
      customRender: 'startAddress'
    },
    align: 'center',
  },
 
  {
    title: '录单员',
    dataIndex: 'salesman',
    align: 'center',
    width: 70,
  },
  {
    title: '结算方式',
    dataIndex: 'orderSettlement.label',
    align: 'center',
    width: 100,
  },
  {
    title: '结算状态',
    dataIndex: 'settlementStatus.label',
    align: 'center',
    width: 80,
  },
  {
    title: '标签',
    dataIndex: 'labelName',
    align: 'center',
    width: 100
  },
  {
    title: '台量类型',
    dataIndex: 'vehicleNum.label',
    align: 'center',
    width: 80
  },
  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    width: 80,
    fixed:'right',
    slots: {
      customRender: 'add'
    }
  },
])
  </script>
  
  <style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}

.statusBox {
  border-bottom: 1px solid #eee;
  background: #fff;
}

.item {
  padding: 10px;
  border-bottom: 4px solid transparent;
  margin-right: 40px;
  font-size: 16px;
}
.changeItem {
  border-bottom: 4px solid #0066cc;
  color: #0066cc;
}
.ant-typography {
  margin: 0;
}
</style>