import { postBodyRequest, getRequest,deleteBodyRequest } from '@/utils/axios'
// 类目list
export function  oaList(params) {
  return postBodyRequest('/oa/knowledge/type/list', params)
}
//添加类目
export function  oaAdd(params) {
  return postBodyRequest('/oa/knowledge/type/add', params)
}
//是否隐藏类目
export function oaHide(params) {
  return postBodyRequest('/oa/knowledge/type/edit-enable', params)
}
//编辑名称
export function oaEdit(params) {
  return postBodyRequest('/oa/knowledge/type/edit-name', params)
}
// 知识库类目tree
export function oaTree() {
  return getRequest('/oa/knowledge/type/tree')
}
//根据关键词 获取提示内容
export function getKeyword(params) {
  return postBodyRequest('/oa/knowledge/content/keyword-prompt', params)
}
// 新增知识库-内容
export function repContentAdd(params) {
  return postBodyRequest('/oa/knowledge/content/add', params)
}
//知识库内容数据
export function repContentList(params) {
  return postBodyRequest('/oa/knowledge/content/list', params)
}
//是否隐藏知识库内容
export function oaHideContent(params) {
  return postBodyRequest('/oa/knowledge/content/edit-enable', params)
}
//知识库内容查询
export function oaFindID(id) {
  return getRequest(`/oa/knowledge/content/${id}`)
}
//知识库编辑
export function oaContentEdit(params) {
  return postBodyRequest('/oa/knowledge/content/edit', params)
}
// 用户浏览内容（统计）
export function oaContentPage(params) {
  return postBodyRequest('/oa/knowledge/content/record-page', params)
}
// ------------------------------ 知识弹窗 ----------------------------
//知识库弹窗全部知识的类别
export function oaGetKeyType() {
  return getRequest('/oa/knowledge/content/keyword-type')
}
// 知识库弹窗根据类别ID获取
export function oaGetTypeId(typeId) {
  return getRequest(`/oa/knowledge/content/keyword-type/${typeId}`)
}
// 知识库弹窗根据关键词 获取提示内容
export function oaKeyPrompt(params) {
  return postBodyRequest('/oa/knowledge/content/keyword-prompt', params)
}
// 更新有用无用
export function oaUpdetaType(params) {
  return postBodyRequest('/oa/knowledge/content/record-fond-type', params)
}
//反馈
export function oaFeedbackAdd(params) {
  return postBodyRequest('/oa/knowledge/feedback/add', params)
}
// ------------------------------ 知识反馈 ----------------------------
// 反馈意见分页
export function feedbackPage(params) {
  return postBodyRequest('/oa/knowledge/feedback/page', params)
}
// 处理反馈内容
export function feedbackDeal(params) {
  return postBodyRequest('/oa/knowledge/feedback/deal', params)
}

// ------------------------------ 知识关联 ----------------------------

// 关联分页
export function contentRelatePage(params) {
  return postBodyRequest('/oa/knowledge/content-relate/list', params)
}
//关联根据标题名称获取内容list
export function relevanceList(params) {
  return postBodyRequest('/oa/knowledge/content/query-title/list', params)
}
//新增关联
export function relateAdd(params) {
  return postBodyRequest('/oa/knowledge/content-relate/add', params)
}
// n内容关联查询
export function findRelateId(id) {
  return getRequest(`/oa/knowledge/content-relate/${id}`)
}
//编辑关联
export function relateEdit(params) {
  return postBodyRequest('/oa/knowledge/content-relate/edit', params)
}
// 解除关联
export function relateDelete(id) {
  return deleteBodyRequest(`/oa/knowledge/content-relate/relieve-relate/${id}`)
}

// AIcoze回复接口
export function cozeChat(question) {
  return getRequest(`/oa/knowledge/coze/chat?question=${question}`)
}

// 获取token
export function cozeSignJwt() {
  return getRequest(`/oa/knowledge/coze/sign/jwt`)
}

// 知识库列表
export function cozeV1Datasets(params) {
  return postBodyRequest('/oa/knowledge/coze/v1/datasets', params)
}

// 上传文件
export function cozeDocumentCreate(params) {
  return postBodyRequest('/oa/knowledge/coze/document/create', params)
}

// 获取智能体id
export function cozeBotId(question) {
  return getRequest(`/oa/knowledge/coze/bot-id`)
}
