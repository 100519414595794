import {
  getRequest,
  postBodyRequest,
  fileUpload
} from '@/utils/axios'
export function pageAll (params) {
  return postBodyRequest('/anal/dispatch-center-console/statistics ', params)
}
// 配置参数
export function configAdd (params) {
  return postBodyRequest('/anal/dispatch-center-console/config-add', params)
}
// 获取省
export function onlyProvince (params) {
  return getRequest(`/anal/dispatch-center-console/only-province/${Math.floor(Math.random() * 1000)}`, params)
}
// 所有小组组员
export function allGroupEmployee (params) {
  return getRequest('/anal/order/config/all-group-employee', params)
}
// 验车任务 - 待委托
export function inspectionTaskNo (params) {
  return postBodyRequest('/anal/dispatch-center-console/inspection-task/no', params)
}
// 验车任务 - 待验车
export function inspectionTaskNoInspect (params) {
  return postBodyRequest('/anal/dispatch-center-console/inspection-task/to-inspect', params)
}
// 验车任务 - 待推送
export function inspectionTaskNoPush (params) {
  return postBodyRequest('/anal/dispatch-center-console/inspection-task/to-push', params)
}
// 提车任务 - 待发运
export function pickLoading (params) {
  return postBodyRequest('/anal/dispatch-center-console/pick/loading', params)
}
// 提车任务 - 待安排
export function pickNo (params) {
  return postBodyRequest('/anal/dispatch-center-console/pick/no', params)
}
// 提车任务 - 已发运 
export function pickTrans (params) {
  return postBodyRequest('/anal/dispatch-center-console/pick/trans', params)
}
// 干线任务 - 待发运
export function transportLoading (params) {
  return postBodyRequest('/anal/dispatch-center-console/transport/loading', params)
}
// 干线任务 - 待安排
export function transportNo(params) {
  return postBodyRequest('/anal/dispatch-center-console/transport/no', params)
}
// 干线任务 - 已发运
export function transportTrans(params) {
  return postBodyRequest('/anal/dispatch-center-console/transport/trans', params)
}
// 送车任务 - 待发运
export function deliveryLoading(params) {
  return postBodyRequest('/anal/dispatch-center-console/delivery/loading', params)
}
// 送车任务 - 待安排
export function deliveryNo(params) {
  return postBodyRequest('/anal/dispatch-center-console/delivery/no', params)
}
// 送车任务 - 已发运
export function deliveryTrans(params) {
  return postBodyRequest('/anal/dispatch-center-console/delivery/trans', params)
}
// 自定义标签
export function customLabel(params) {
  return postBodyRequest('/anal/dispatch-center-console/custom-label', params)
}
// 发车计划
export function waitPlan(params) {
  return postBodyRequest('/anal/dispatch-center-console/wait-plan', params)
}
// 检查车辆照片-页面
export function dispatchPage(params) {
  return postBodyRequest('/anal/dispatch-center-console//check-vehicle-pic', params)
}
// 检查交车条
export function deliveryCheck(params) {
  return postBodyRequest('/anal/dispatch-center-console/delivery-check', params)
}
// 在途跟踪
export function onWayTrack(params) {
  return postBodyRequest('/anal/dispatch-center-console/on-way-track', params)
}

// 即将到达
export function comingArrival(params) {
  return postBodyRequest('/anal/dispatch-center-console/coming-arrival', params)
}
// 外派监控
export function taskMonitor(params) {
  return postBodyRequest('/anal/dispatch-center-console/task-monitor', params)
}
// 历史发运数据
export function offerTransportHis(params) {
  return postBodyRequest('/anal/dispatch-center-console/offer-transport-his', params)
}
// 超时车辆分析
export function timeoutVehicleAnl(params) {
  return postBodyRequest(`/anal/dispatch-center-console/timeout-vehicle-anl`,params )
}

// 关注列表
export function focusList(params) {
  return postBodyRequest(`/anal/vehicle/focus/list`,params )
}
// 干线任务 - 清零
export function transportZero(params) {
  return postBodyRequest(`/anal/dispatch-center-console/transport-zero`,params )
}

// 调度待接单列表
export function transportWaiteReceive(params) {
  return postBodyRequest(`/anal/dispatch-center-console/waite-receive`,params )
}
// 调度接单
export function dispatchReceive(params) {
  return postBodyRequest(`/trans/order/vehicle-task/dispatch/receive`,params )
}
// 订单所有车辆任务备注
export function orderSetRemark(params) {
  return postBodyRequest(`/trans/order/vehicle-task/order/set/remark`,params )
}